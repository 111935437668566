.activity-c {
  margin-top: -20px;
  @media print {
    display: none;
  }
}

.activity {
  @include flex-row();

  padding-bottom: 10px;
  padding-top: 10px;
  margin: 0 10px 5px 10px;
  border: 1px solid #eee;
  border-radius: 8px;

  &__col-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 76px;
  }

  &__col-2 {
    .timeago {
      color: #aaa;
      font-size: 0.95rem;
    }
  }

  &__created {
    font-size: 0.8em;
    color: $light-color;
    line-height: 1.1;
    text-align: center;
    margin-top: 2px;
  }
}
