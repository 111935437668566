.flex {
  display: flex;
  &-column {
    flex-direction: column;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.ai-c {
  align-items: center;
}

.jc-c {
  justify-content: center;
}

.flex-row-and-cols {
  @include flex-row
}

.no-select {
  user-select: none;
}

.error-color {
  color: $error-color
}

.success-color {
  color: $success-color
}

.overflow-hidden {
  overflow: hidden;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-nowrap {
  white-space: nowrap;
}

.disabled-anchor {
  pointer-events: 'none';
  opacity: 0.5;
}
