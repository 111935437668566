body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: $body-bg-color;
  text-align: left;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  color: $default-text-color;
}

#root, .page-c {
}

a, .link {
  text-decoration: none;
  color: $accent-color;
  padding: 0;
  appearance: none;
  background: none;
  border: none;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: underline;
    color: $blue-accent-color-hover
  }
}

button.link[disabled] {
  text-decoration: none;
  color: lighten($accent-color, 10%);
}

.news-notification {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: $orange-tint-color;
  padding: 5px !important;
  padding-left: 40px !important;

  a {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #fff;
  }
  a:hover {
    text-decoration: none !important;
  }
  svg.news-icon {
    width: 32px !important;
    height: 32px !important;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 10px;
  }
  .news-body {
    font-size: 1.1rem;
    flex-grow: 1;
    flex-shrink: 1;
  }
  .news-label {
    font-weight: bold;
    font-size: 0.9rem;
    opacity: 0.6;
    text-decoration: none !important;
  }
  a.close-button {
    width: 30px;
    flex-grow: 0;
    flex-shrink: 0;
    color: #fff;
    opacity: 0.6;
  }
  a.close-button:hover {
    opacity: 1;
  }
}

.news-notification:hover {
  background-color: #c9791b;
}


.settings-news .news-notification {
  border-radius: 6px;
  padding-left: 10px !important;
}


.view-news {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.no-news-icon {
  color: #aaa;
  svg {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
}

.account-warnings {
  background-color: $error-color;
  padding: 10px !important;
  padding-left: 20px !important;
  color: #fff;
  display: flex;
  flex-direction: row;
  .icon {
    flex-grow: 0;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 15px;
    color: $error-color;
    text-align: center;
    line-height: 30px;
    font-weight: bold;
    font-size: 1.5rem;
  }
  .message {
    flex-grow: 1;
    flex-shrink: 1;
    margin-left: 10px;
  }
}

// Nav

nav.primary-nav {
  background-color: $extra-dark-color;
  background-image: url('../assets/images/primary-nav-bg.jpg');
  background-repeat: no-repeat;
  background-position: 0 100%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: $primary-nav-width;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 18px rgba(0,0,0,0.3);
  z-index: 999;
  @media print {
    display: none;
  }
}

.primary-nav-items {
  flex: 1;
}

nav.primary-nav i {
  font-size: 1.8rem;
}

nav.primary-nav a {
  color: $nav-text-color;
  display: flex;
  flex-direction: column;
  text-align: center;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  height: 90px;
  opacity: 0.6;
  position: relative;
  svg.active-indicator {
    position: absolute;
    left: 100%;
    top: 34px;
    display: none;
  }
  svg.active-indicator polygon {
    fill: $blue-accent-color;
  }
  svg {
    color: #fff;
  }
  .icon {
    svg {
      margin: 0 auto;
    }
  }
  .regional-reports-icon {
    svg {
      margin-bottom: 5px;
      margin-top: 4px;
    }
  }
  .extension-icon svg {
    width: 46px;
    height: 32px;
  }
}

nav a:hover {
  background-color: #292d38;
  color: #717a89;
}

nav.primary-nav a div.label {
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 5px;
  display: block;
}

nav.primary-nav a div.regional-reports-label {
  line-height: 0.95rem;
  margin-top: -2px;
  margin-bottom: 8px;
}

nav.primary-nav a.active {
  background-color: $blue-accent-color;
  color: #fff;
  opacity: 1.0;
  svg {
    display: inherit;
  }
}

.nav-logo {
  width: 65px;
  height: 21px;
  margin: 26px auto 20px auto;
}

nav.primary-nav a.active .nav-logo {
  opacity: 1.0;
}

nav.primary-nav a .badge {
  position: absolute;
  width: 22px;
  height: 22px;
  background: -moz-linear-gradient(top, #d32a2a 0%, #a02020 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #d32a2a 0%,#a02020 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #d32a2a 0%,#a02020 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  box-shadow: 0px 2px 4px rgba(0,0,0,0.2);
  top: 16px;
  right: 16px;
  border-radius: 15px;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    margin-top: 0px;
  }
}

// Content headers

.content-header {
  display: flex;
  padding-bottom: 5px;

  h1 {
    flex-grow: 1;
    flex-shrink: 1;
    color: $medium-dark-color;
    font-size: 2.5rem;
    padding-top: 8px;
    margin: 0;
    margin-bottom: 5px;
    font-weight: bold;
    @media screen and (max-width: $valuation-break-small) {
      font-size: 1.65rem;
      margin-bottom: 0;
    }
    @media screen and (max-width: $break-extension) {
      font-size: 2rem;
    }
  }
  h2 {
    font-weight: normal;
    font-size: 1.3rem;
    letter-spacing: -0.4px;
    color: $light-color;
    margin-top: 0;
    margin-bottom: 10px;
    padding-top: 0;
    @media screen and (max-width: $valuation-break-small) {
      font-size: 1rem;
    }
  }
  .global-tools {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .account-bug-c {
    text-align: right;
    padding-top: 4px;
    a {
      margin-left: $standard-margin;
    }
  }
}


a.stale-data-reload {
  background-color: $blue-accent-color;
  padding: 4px 8px 4px 20px;
  border-radius: 18px;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  img {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }
}

.content-context-actions {
  margin-bottom: $standard-margin * 2;
  color: #dbdde4;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  span {
    display: flex;
  }
  a, span.disabled {
    margin: 0 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    svg {
      margin-right: 5px;
    }
  }
  a:first-child {
    margin-left: 0;
    padding-left: 0;
  }
  span.disabled {
    color: #aaa;
  }
}

.search-content-context-actions {
  margin-top: 5px;
}

.search-content-context-actions a, a.saved-search-save-enabled {
  border: 1px solid $accent-color;
  border-radius: 4px;
  padding-right: 5px;
  margin-right: 10px;
  margin-left: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  svg {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.search-content-context-actions a:hover {
  background-color: $accent-color;
  color: #fff;
  text-decoration: none;
  margin-left: 0;
  padding-left: 0;
}



a.saved-search-save-enabled {
  background-color: $accent-color;
  color: #fff;
}

a.fixed-saved-search-save-button {
  position: fixed;
  z-index: 1000;
  animation-timing-function: ease;
  animation-name: zoomIn;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  box-shadow: 0 5px 16px rgba(0,0,0,0.25);
}

a.fixed-saved-search-save-button:hover {
  text-decoration: none;
  background-color: $accent-color-highlight;
  border-color: $accent-color-highlight;
}


// Secondary nav

nav.secondary-nav {
  background-color: $medium-dark-color;
  background-image: url('../assets/images/secondary-nav-bg.jpg');
  background-repeat: no-repeat;
  background-position: 0 100%;
  position: fixed;
  box-shadow: 0 0 16px rgba(0,0,0,0.35);
  position: fixed;
  left: 90px;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: $secondary-nav-width;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 995;
  color: $light-color;


  @media print {
    display: none;
  }

  .secondary-nav-links {
    flex-grow: 1;
    flex-shrink: 1;
  }

  .secondary-nav-footer {
    height: 90px;
    flex-grow: 0;
    flex-shrink: 0;
    padding: $standard-margin;
    display: flex;
    justify-content: flex-end;
  }
}

nav.secondary-nav::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: rgba(0, 0, 0, 0.2);
  width: 8px;
}

nav.secondary-nav::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
  -webkit-border-radius: 1ex;
}

nav.secondary-nav::-webkit-scrollbar-corner {
  background: #000;
}

nav.secondary-nav section {
  margin-top: $standard-margin;
  .section-label {
    color: #fff;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: bold;
    padding-left: $standard-margin + 12px;
  }
  h3 {
    color: #fff;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: bold;
    margin-left: $standard-margin + 12px;
    margin-top: 40px;
    margin-bottom: 5px;
  }
  h3.with-control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      margin-right: 16px;
      width: 20px;
      height: auto;
      cursor: pointer;
    }
  }
  .global-filters-row {
    .icon {
      width: 18px !important;
      height: 18px !important;
    }
    font-weight: bold;
  }

  a.upsell-feature {
    color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    margin-right: 20px;
    opacity: 0.4;
    svg {
      color: #fff;
    }
  }

  a.upsell-feature:hover {
    color: #02b1fc;
    border: 1px solid #02b1fc;
    opacity: 1;
    box-shadow: 0 0 10px #03709e;
  }
}

nav.secondary-nav a, nav.secondary-nav button.nav-choice {
  color: $nav-text-color;
  display: flex;
  text-decoration: none;
  padding: 8px 12px;
  margin-left: $standard-margin;
  margin-bottom: 5px;
  border-radius: 4px 0 0 4px;

  transition: background-color 0.2s ease;
  position: relative;
  overflow: visible;
  box-sizing: border-box;
  align-items: center;

  svg.icon {
    width: 18px !important;
    height: 18px !important;
    margin-right: 8px !important;
    color: $light-color;
  }
}

nav.secondary-nav button.nav-choice {
  background-color: transparent;
  border: 0;
}

nav.secondary-nav a:hover, nav.secondary-nav button.nav-choice:hover {
  background-color: $extra-dark-color !important;
  color: #fff;
}

nav.secondary-nav a.active {
  background-color: $blue-accent-color !important;
  // background-color: #111 !important;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 1px #017baf;
  svg.icon, svg.my-lot-nav-icon {
    color: #fff !important;
  }
}

nav.secondary-nav a span {
  flex: 2;
}
nav.secondary-nav a span:nth-child(2) {
  text-align: right;
  flex: 1;
}

nav.secondary-nav .vin-entry {
  background-color: #363c4c;
  border: 1px solid #404659;
  color: #fff;
  margin-top: 10px;
}

nav.secondary-nav .split a {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;

}

nav.secondary-nav {
  .droppable {
    background-color: #59637b !important;
    border-radius: 8px;
    a {
      color: #ddd !important;
    }
  }
  .droppable-active {
    background-color: $blue-accent-color !important;
    border-radius: 8px;
    a {
      color: #fff !important;
    }
  }
}


.new-valuation-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #fff !important;

  .new-valuation-round-button {
    display: flex;
    align-items: center;
    margin-left: 10px;
    padding: 0 !important;
    width: 50px !important;
    height: 50px !important;
    background-color: $accent-color !important;
    border-radius: 25px !important;
    box-shadow: 0px 6px 15px rgba(0,0,0,0.2);
  }
}

.new-valuation-button:hover {
  background-color: transparent !important;
}

.new-valuation-button:hover .new-valuation-round-button {
  background-color: $accent-color-highlight !important;
}


#new-version-notice {
  display: block;
  margin-left: $secondary-nav-width + 90;
  background-color: #000;
  color: #fff;
  font-weight: bold;
  padding: 10px;
}
#new-version-notice:hover {
  text-decoration: none;
  background-color: $blue-accent-color;
}


// Page generc containers

.page-body-c {
  background-color: #f6f7fa;
  overflow: auto;

  margin-bottom: 0;
}

.body-content {
  padding: $standard-margin;
  padding-left: $standard-margin * 2;
  min-height: 400px;

  @media print {
    width: 100% !important;
    padding: 0;
  }

  @media screen and (max-width: $valuation-break-small) {
    margin: 0;
  }
}

.body-with-nav {
  margin-left: $primary-nav-width + $secondary-nav-width;
  padding-bottom: $standard-margin * 2;

  @media print {
    margin-left: 0;

  }
}



// Account bug
.account-bug-c {
  display: flex;
  flex-direction: row;
  align-items: center;
  @media print {
    display: none;
  }
}
.account-bug-logo {
  display: none;
  @media print {
    display: inherit;
    width: 100px;
    height: 32px;
  }
}
.notification-alert {
  margin-right: 30px;
  color: #aaa;
  cursor: pointer;
}
.account-bug {
  @include flex-row(5px);
  cursor: pointer;
  align-items: center;
  max-width: 400px;
}

.account-bug-dropdown {
  background-color: #fff;
  box-shadow: 0px 6px 12px rgba(0,0,0,0.2);
  margin-top: 10px;
  border-radius: 8px;
  max-width: 500px;

  button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .icon {
      width: 26px;
      text-align: center;
      margin-right: 5px;
    }
    .account-icon {
      font-size: 1.2rem;
    }
  }
}

.account-dropdown-name {
  font-size: 20px;
  color: $medium-dark-color;
}


// Calculator bug
.calculator-bug-c {
  position: relative;
  margin-top: 10px;
  margin-left: 20px;
}

a.calculator-bug {
  display: block;
}

a.calculator-bug:hover {
  transform: scale(1.25);
  box-shadow: 0 4px 36px rgba(0,0,0,0.12);
  transition: all 0.1s ease-out;
}


// Calculator

.global-calculator-c {
  position: fixed;
  z-index: 9999;
  width: 340px;
  right: 10px;
  top: 66px;
  box-shadow: 0px 6px 44px rgba(0,0,0,0.4);
  background-color: #fff;;

  border-radius: 10px;
  overflow: hidden;
}

.embedded-calculator-c {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 0 !important;
}

.calculator-c {
  width: 100%;
  > h2 {
    margin-bottom: 0px !important;
    padding-bottom: 0 !important;
    font-size: 16px !important;
    letter-spacing: 0;
    color: $medium-dark-color;
    font-weight: 500;
  }

  .calculator-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px 0;
    h2 {
      margin: 0;
      margin-bottom: 3px;
      padding: 0;
    }
    .calculator-row-cell {
      width: 100%;
      text-align: center;

      h2 {
        text-align: center;
        font-size: 0.8rem !important;
        margin: 0;
        font-weight: bold;
      }
      .input-c {
        margin: 0 auto;
        input {
          width: 130px;
          margin: 0 auto;
          border: 1px solid #aaa;
          background-color: #eee;
          font-size: 1.1rem;

        }
        .currency-input-label {
          margin: 0px 8px !important;
        }
      }
    }
    .calculator-row-cell-split {
      width: 40%;
      @media screen and (max-width: $break-medium) {
        input {
          width: 96px !important;
        }
      }
    }
    .lockable-c {
      width: 180px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      a.input-lock-button {
        display: block;
        margin-left: 10px;
        width: 40px !important;
        flex-grow: 0;
        flex-shrink: 0;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #aaa;
        box-shadow: 0px 2px 4px rgba(0,0,0,0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          margin: auto;
        }
      }
    }
  }

  .calculator-row-lockable {
    height: 90px;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .locked-c {
    height: 100%;
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;

    background-color: #333;
    color: #fff;
    box-shadow: inset 0 2px 16px rgba(0,0,0,0.3);
    margin: 0 10px;

    .locked-body {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Courier New', Courier, monospace;
      font-size: 1.55rem;
      letter-spacing: -1px;
      font-weight: bold;
      h2 {
        margin-right: 10px !important;
        color: #fff;
        opacity: 0.8;
        font-size: 1.2rem;
        letter-spacing: -1px;
        margin-top: 2px;
        text-align: right;
      }
    }

  }
  .profit-positive {
    background: linear-gradient(180deg, #288272 0%, #237776 100%);
  }

  .profit-negative {
    background: linear-gradient(180deg, #822830 0%, #77233c 100%);
  }

  .calculator-footer {
    border-top: 1px solid #ddd;
    padding: 6px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0 !important;
    a {
      color: #111 !important;
      opacity: 0.7;
      svg {
        margin-top: -3px;
      }
    }
    a:hover {
      opacity: 1;
      text-decoration: none;
    }
  }
  .value-note {
    text-align: center;
    color: #777;
  }
}

.embedded-calculator-c {
  .input-c {
    input {
      width: 110px !important;
    }
  }
  .lockable-c {
    width: 160px !important;
  }

  .locked-body {
    font-size: 1.2rem !important;
  }
  .calculator-row-cell h2 {
    font-size: 0.9rem !important;
  }
}

.global-calculator-dismiss-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9998;
  background-color: transparent;
}


// Login

.login-c {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../assets/images/login-bg.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.login-c .login {
  width: 400px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  @media screen and (max-width: $break-small) {
    width: 90%;
  }
}

.login-logo {
  width: 200px;
  height: 63px;
  margin: 100px auto $standard-margin auto;
  @media screen and (max-width: $break-small) {
    margin-top: 40px;
    margin-bottom: 0;
    width: 140px;
    height: 44px;
  }
}

.login form input {
  border: none;
  border-radius: 0;
  color: #fff;
  font-size: 1.1rem;
}

.login form input::placeholder {
  color: #fff;
  opacity: 0.7;
}

.login form .capsule-button {
  border-radius: 10px;
  padding: 10px;
  margin-top: $standard-margin;
  background-color: #d3b043;
  font-weight: bold;
  font-size: 1.2rem !important;
  cursor: pointer;
}

.login form a {
  color: #fff !important;
}

input::-webkit-credentials-auto-fill-button {
  background-color: rgba(255,255,255,0.1);
}

.login form input.field {
  background-color: transparent;
  border-bottom: 1px solid rgba(255,255,255,0.75);
  padding-bottom: 5px;
}

.login-secondary a {
  display: block;
  margin-top: 20px;
  color: rgba(255,255,255,0.7);
  text-align: center;
  text-decoration: none;
}

.login-secondary a:hover {
  color: rgba(255,255,255,1.0);
}

.login-failed {
  min-height: 30px;
  color: red;
}

.login-submit-c {
  height: 60px;
}

.login-spinner-c {
  height: 50px;
}



/* Generic lists */
.grid-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  display: grid; /* 1 */
  grid-template-columns: repeat(auto-fill, 220px); /* 2 */
  grid-gap: 1rem; /* 3 */
  justify-content: space-between; /* 4 */

  // justify-content: flex-start;
  // justify-content: space-between;
  gap: 12px;
  h2 {
    grid-column: 1/-1;
  }
  .grid-list-cell {
    // margin-right: 20px;
  }
  .grid-list-cell:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 36px rgba(0,0,0,0.12);
    transition: all 0.1s ease-out;
  }
}
.grid-list a {
  text-decoration: none;
}

.grid-list a:hover > div {

}



/* Valuations */
.vehicle-row-c {
  background-color: #f8f9fa;
}

.vehicle-row {
  width: 100%;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .thumbnail {
    flex-grow: 0;
    flex-shrink: 0;
    width: 57px;
    margin-right: 10px;
    img {
      width: 57px;
      height: 57px;
      border-radius: 4px;
    }
  }
  .overview {
    flex-grow: 1;
    flex-shrink: 1;
    h3 {
      @media screen and (max-width: $break-medium) {
        font-size: 1.1rem !important;
      }
    }
    .trim {
      @media screen and (max-width: $break-large) {
        font-size: 0.8rem;
      }
    }
  }
  .details {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    svg {
      margin-top: -1px
    }
    > div {
      margin-right: 5px;
    }
    .vin {
      width: 220px;
      @media screen and (max-width: $break-medium) {
        font-size: 0.9rem;
        width: 190px;
      }
    }
    .mileage {
      width: 120px;
      @media screen and (max-width: $break-medium) {
        font-size: 0.9rem;
        width: 100px;
      }
    }
    .created {
      width: 50px;
      @media screen and (max-width: 1500px) {
        display: none;
      }
    }

    .folder {
      width: 200px;
      align-items: center;
      span {
        color: $light-color !important;
        svg {
          margin-right: 5px;
        }
      }
      @media screen and (max-width: $break-medium) {
        font-size: 0.85rem;
        width: 150px;
        span {
          font-weight: normal !important;
        }
        img {
          display: none;
        }
      }
    }
    .assigned-folder {
      span {
        color: $medium-dark-color !important;
      }
    }
    .target-notes-c {
      width: 106px;
    }
    .more-button {
      text-align: right;
      width: 34px;
      padding-right: 0;
      cursor: pointer;
    }
  }
}

.grid-list .valuation-preview {
  width: 230px;
  min-height: 130px;
  margin-right: $standard-margin;
  margin-bottom: 30px;
  padding: 6px;
  padding-left: 30px;
  padding-top: $standard-margin;
  border-radius: $section-border-radius;
  overflow: hidden;
  box-shadow: 0px 10px 20px #dfdfe6;
  border: 1px solid #ddd;
  color: #111;
  transition: all .1s ease-in-out;
  position: relative;
}

.vehicle-row-actions {
  a {
    display: block;
    font-size: 1.1rem;
    margin-bottom: 5px;
  }
  a:hover {
    text-decoration: none;
  }
}



/* Valuation Show */

.shared-page-c {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  .body-content {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.shared-page-c .legal {
  margin: 140px auto 40px auto;
  padding: 0 20px;
  text-align: center;
}

.shared-valuation-header {
  width: 100%;
  margin: 10px auto 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 0 $standard-margin;
  img {
    margin-left: 2px;
    width: 100px;
    height: 32px;
  }
  div {
    margin-right: 2px;
  }

  @media screen and (max-width: $break-small) {
    display: block;
    padding: 0 10px;
    div {
      font-size: 0.8rem;
    }
  }
}

.valuation-hud {
  position: fixed;
  top: 0;
  left: $primary-nav-width + $secondary-nav-width;;
  right: 0;
  padding: 5px 0;
  padding-left: $standard-margin * 2;
  background-color: rgba(32, 35, 38, 1.0);
  color: #fff;
  text-decoration: none;
  font-size: 1.1rem;
  z-index: 9999;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  div, svg {
    margin-right: $standard-margin;
  }
}
.valuation-hud:hover {
  color: #fff;
  text-decoration: none;
}
.fadeOut{
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.2s, opacity 0.2s linear;
}
.fadeIn{
  opacity: 1;
  visibility: visible;
  transition: visibility 0s, opacity 0.2s linear;
}

.quickjump-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff !important;
  background-color: $accent-color !important;
  border: 1px solid #ddd !important;
  padding: 2px 8px !important;
  border-radius: 4px;;
  .quickjump-choices-c {
    position: absolute;
    right: 0;
    top: 30px;
    font-weight: normal;
    text-align: left;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 5px 8px;
    display: flex;
    flex-direction: column;
    button {
      padding: 4px 8px;
      display: block;
      width: 100%;
      color: #111;
      text-decoration: none;
    }
    button:hover {
      background-color: #eee;
    }
  }
}

.valuation-header {
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: $standard-margin;
  padding-bottom: 5px;
  font-size: 1.2rem;
  border-bottom: 2px solid #ddd;

  @media screen and (max-width: $valuation-break) {
    flex-wrap: wrap;
    font-size: 0.9rem;
    .label {
      font-size: 0.9rem !important;
    }
  }

  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  div:not(.label) {
    @media screen and (max-width: $break-extension) {
      margin-right: 10px;
    }
  }
  .label {
    color: $light-color;
    font-size: 1.1rem;
    font-weight: normal;
    padding-right: 5px;
  }

  a {
    color: $blue-accent-color;
    color: red;
  }
  a.warn {
    background-color: rgba(255, 0, 0, 0.1);
    padding: 0 5px;
    border-radius: 4px;
  }

}

.valuation-body {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  @media screen and (max-width: $valuation-break) {
    flex-direction: column-reverse;
  }
  .valuation {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
    width: 100%;
  }
  .valuation-sidebar {
    width: 290px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: $standard-margin;
    @media screen and (max-width: $break-medium) {
      width: 240px;
    }
    @media screen and (max-width: $valuation-break) {
      width: 100%;
      margin-right: 0;
    }
  }
}

.valuation-partners {
  margin-bottom: $standard-margin * 2;
  padding-left: $standard-margin;
  svg {
    margin-right: 5px;
  }
}


a.edit-condition-condition {
  color: #111;
  background-color: #fff;
  border-radius: 5px;
  display: block;
  border-bottom: 1px solid #ddd;
  padding: 5px;
}

a.edit-condition-condition:hover {
  background-color: #eee;
  text-decoration: none;
}

a.edit-condition-condition-selected, a.edit-condition-condition-selected:hover {
  color: #fff;
  background-color: $blue-accent-color;
}

.vehicle-found-external-c {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
  opacity: 0;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  -webkit-animation: 0.8s ease 0.4s normal forwards 1 fadeInDown;
  animation: 0.8s ease 0.4s normal forwards 1 fadeInDown;
}

.vehicle-found-external-extension-c {
  display: block;
}

.vehicle-found-external {
  .vehicle-found-external-type-label {
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  a {
    min-height: 53px;
    color: $default-text-color;
    color: $default-text-color;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    padding: 4px 10px;
    padding-left: 14px;
    margin-bottom: 20px;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .photo-container {
      width: 38px;
      height: 38px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      border-radius: 6px;
      margin-left: -9px;
      margin-right: 10px;
    }
    .external-details {
      flex-grow: 1;
      flex-shrink: 1;
      font-size: 1.1rem;
      margin-right: 20px;
      .external-location div {
        max-width: 210px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 0.9rem;
      }
    }
  }
  a:hover {
    text-decoration: none;
    background-color: #eee;
    border: 1px solid #aaa;
  }

}


@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.vehicle-embedded-choices {
  padding: $standard-margin;
  border-bottom: 2px solid $medium-dark-color;
}

.valuation-footer {
  min-height: 3rem;
}

.valuation-section .valuation-subsection:first-child {
  margin-top: $standard-margin;
}

.valuation-subsection {
  margin-top: $standard-margin * 2;
}

.valuation-subsection-callout {
  padding: 10px;
  background-color: #eee;
  border-radius: 5px;
}

h3.valuation-subsection-title {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 1.2rem;
  @media screen and (max-width: $break-extension) {
    font-size: 1rem;
  }
}

section.valuation-section, section.listing-section {
  background-color: #fff;
  padding: $standard-margin;
  margin-bottom: $standard-margin * 3;
  h2 {
    margin-top: 0;
    padding: 0;
    font-size: 2.1rem;
    line-height: 2.1rem;
    font-weight: bold;
    @media screen and (max-width: $valuation-break-small) {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
    @media screen and (max-width: $break-extension) {
      font-size: 1.7rem;
      line-height: 1.7rem;
    }
  }
}

section.valuation-section-collapsed {
  margin-bottom: $standard-margin;
}

.highlighted {
  background-color: $highlight-color;
  border-radius: 5px;
  font-weight: bold;
}

.similar-vehicles-preview-c {
  margin-top: 40px;
  h2 {
    margin-bottom: 10px;
    margin-left: 0 !important;
  }

}

.similar-vehicles-preview-grid {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  a {
    display: block;
    width: 100%;
    width: 48%;
    margin-bottom: 20px;
  }
  a:hover {
    text-decoration: none;
    transform: scale(1.02);
    transition: all 0.1s ease-out;
  }
}

// Big numbers
.summary-c {

}

.summary-data {
  @media screen and (max-width: $valuation-break) {
    display: flex;
    flex-direction: row;
  }
}

.big-c {
  width: 100%;
  padding: 10px 20px;
  color: #fff;

  @media screen and (max-width: 800px) {
    width: 50%;
  }
  .big-type-label {
    font-size: 1rem;
    font-weight: bold;
    opacity: 0.7;
    @media screen and (max-width: $valuation-break-small) {
      font-size: 0.9rem;
    }
  }
  .big-value {
    font-size: 2.4rem;
    font-weight: bold;
    @media screen and (max-width: $valuation-break-small) {
      font-size: 1.6rem;
    }

  }
}

.big-wholesale-c {
  background-color: #505d62;
  @media screen and (max-width: 800px) {
    border-radius: 0;
  }
}

.big-retail-c {
  background-color: $blue-accent-color;
  @media screen and (max-width: 800px) {
    border-radius: 0;
  }
}

.summary-messages {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 20px 20px 0 20px;
  @media screen and (max-width: $valuation-break-small) {
    margin-left: 0;
    padding: 10px 10px 0 10px;
  }
}


// Data tables
.data-table {
  width: 100%;
  border-spacing: 0;
  thead {
    background-color: #272c37;
    color: #fff;
    .sort-indicator {
      font-size: 0.8rem;
      color: #b8c2d7;
      margin-left: 5px;
    }
  }
  thead a {
    color: #fff !important;
  }
  thead a:hover {
    text-decoration: none;
    color: #fff;
  }
  thead th div {
    display: flex;
    align-items: center;
  }
  thead th:first-child {
    border-top-left-radius: 5px;
  }
  thead th:last-child {
    border-top-right-radius: 5px;
  }
  tr:hover {
    background-color: #bbb;
  }
  thead tr:hover {
    background-color: #272c37;
  }
  tr.data-row {
    background-color: #fff;
    border-bottom: 1px solid #ddd;
  }
  tr.data-row:hover {
    background-color: #eee;
  }
  tr.narrow-range-match {
    background-color: #cff2d5;
    border-bottom: 1px solid #b6dabc;
  }
  tr.narrow-range-match:hover {
    background-color: #c8ebce;
  }
  tr.broad-range-match {
    background-color: #edfff0;
    border-bottom: 1px solid #d7e7d9;
  }
  tr.broad-range-match:hover {
    background-color: #e4f6e7;
  }
  tr.highlighted-match {
    background-color: $accent-color;
    color: #fff;
  }
  tr.highlighted-match:hover {
    background-color: $accent-color-highlight;
  }
  th, td {
    padding: 4px 5px;
    @media screen and (max-width: $break-small) {
      font-size: 0.8rem;
    }
  }
  @media screen and (max-width: $break-small) {
    .header-pmr-location, .col-pmr-location, .color-name {
      display: none;
    }
  }
}


// Adjustments view
a.adjustments-toggle {
  color: $blue-accent-color;
}

.adjustments-table-row {
  background-color: #3b6978 !important;
  opacity: 1;
  color: #fff;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.adjustments-row-label {
  background-color: #204051 !important;
}


// Add Deducts

.add-deducts-c {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  .add-deduct-parent {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 2px;
  }

  .add-deduct {
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    @media screen and (max-width: $break-extension) {
      padding: 5px;
    }
    .add-deduct-details {
      flex-grow: 1;
      flex-shrink: 1;
      .add-deduct-title {
        font-weight: bold;
        @media screen and (max-width: $break-extension) {
          font-weight: normal;
          font-size: 0.98rem;
        }
      }
    }
    .add-deduct-controls {
      width: 30px;
      flex-grow: 0;
      flex-shrink: 0;
      img {
        width: 24px;
        height: 22px;
      }
    }
  }
  .add-deduct-children {
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 10px;
  }

  .add-deduct-disabled {
    cursor: default;
    opacity: 0.5;
  }

  .add-deduct:hover {
    background-color: #eee;
  }
  .add-deduct-selected {
    background-color: #eee;
  }
}

// Valuation share
.valuation-share-providers {
  margin-top: 20px;
  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 2px;
    padding: 0;
    height: 32px;
    font-size: 1.1rem;

    input {
      width: 30px;
      margin-top: 4px;
    }
    a {
      color: $default-text-color;
    }
    a:hover {
      text-decoration: none;
      color: #000;
    }
  }
}

// Valuation notes
.valuation-notes-c {
  margin-top: 2rem;
  margin-bottom: 2rem;
  .valuation-notes {
    margin-bottom: 40px;
    .valuation-note {
      padding: 10px 0;
      border-bottom: 1px solid #eee;
      display: flex;
      flex-direction: row;
      .avatar {
        width: 40px;
        flex-grow: 0;
        flex-shrink: 0;
      }
      .note-body {
        flex-grow: 1;
        flex-shrink: 1;
      }
    }
  }
  .save-note-button-c {
    display: flex;
    justify-content: flex-end;
    margin: 0;
  }
  textarea {
    height: 140px;
    margin: 0;
  }
}

.photos-c-header {
  margin: 0 20px;
  @media screen and (max-width: $break-small) {
    margin-left: 0;
    margin-right: 0;
  }
}

.valuation-notes-header {
  h2 {
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
  }
  @media screen and (max-width: $break-small) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.valuation-photos-c {
  margin-top: 1rem;
  @media screen and (max-width: $break-small) {
    margin-left: 20px;
    margin-right: 20px;
  }
}
.valuation-photos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
  img {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}


.valuation-sidebar {
  .card {
    padding-bottom: $standard-margin;
    margin-bottom: $standard-margin;
  }
  h2 {
    font-size: 1rem;
    margin: 10px 20px 10px 20px;
    letter-spacing: 0;
    color: $medium-dark-color;
  }
  .valuation-target-price-c {
    padding: 0 $standard-margin;
    margin-top: 0;
    input {
      margin-bottom: 0;
      padding-left: 25px;
      font-size: 1.2rem;
    }
    i {
      position: absolute;
      display: block;
      top: 50%;
      transform: translate(0, -50%);
      pointer-events: none;
      width: 30px;
      text-align: center;
      font-style: normal;
      color: #888;
      font-size: 1.2rem;
    }
  }
  .valuation-photos-c {
    padding: 0 $standard-margin;
    margin-top: 0;
    .valuation-photos {
      justify-content: space-between;
      img {
        width: 82px;
        height: 82px;
        margin-right: 0;
      }
    }
  }
  .valuation-notes-c {
    padding: 0 $standard-margin;
    margin-top: 0;
    margin-bottom: 0;
    .valuation-note:first-child {
      margin-top: 0;
    }
  }
  .my-lot-button {
    width: 100%;
    margin-bottom: 20px;
    background-color: $accent-color;
    border-color: $accent-color;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    img {
      width: 38px;
      height: 24px;
      margin-right: 10px;
    }
  }
  .my-lot-button:hover {
    background-color: $accent-color-highlight;
    border-color: $accent-color-highlight;
  }
}



.valuation-preview-notes {
  max-height: 370px;
  overflow: scroll;
  .note {
    border-bottom: 1px solid #ddd;
    padding: 5px 0;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
  }
}


.valuation-owner {
  padding: 10px;
  padding-bottom: 10px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  .owner-avatar {
    margin-right: 10px;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .owner-desc {
    font-size: 0.9rem;
    font-style: italic;
    line-height: 1.0rem;
  }
}

// Valuation choices
.valuation-choices-c {
  margin-bottom: 20px;
  h1 {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 1.75rem;
  }

  .existing-choices {
    margin-bottom: 10px;
  }

  .choices-provider-c {
    display: flex;
    flex: 1;
    flex-direction: column;

    border-bottom: 1px solid #ddd;
    padding: 15px 0;
    .choices-provider-c-choices {
      display: flex;
      flex-wrap: wrap;
      button {
        margin-right: 10px;
        margin-bottom: 5px !important;
      }
    }

  }
  .choices-provider-name {
    font-weight: bold;
  }
}

.valuation-choice-button {
  font-weight: normal !important;
  font-size: 1rem !important;
  background-color: $light-dark-color !important;
  border: 1px solid $light-dark-color !important;
}

.valuation-choice-button:hover {
  background-color: $accent-color !important;
  border: 1px solid $accent-color !important;
}

.back-to-top-footer {
  text-align: center;
}


// Valuation new
.new-valuation {
  padding: 0 $standard-margin $standard-margin $standard-margin;

  .vehicle-exists .created {
    font-style: italic;
    color: #aaa;
  }
  .vehicle-exists h3 {
    font-size: 1rem;
  }
  .vehicle-exists-buttons {
    margin-top: 20px !important;
  }
  .vehicle-exists-buttons button {
    font-size: 1rem !important;
  }
  .vehicle-exists-buttons svg {
    font-size: 1.5rem;
    margin-top: -2px;
    margin-right: 5px;
  }
}

.new-valuation-form {
  section {
    margin-bottom: 10px;
    padding-bottom: 12px;
    border-bottom: 2px solid #eee;
  }
  section:nth-child(4) {
    border-bottom: none;
  }
  input.vin {
    font-size: 2rem;
    color: $default-text-color;
  }

  input.vin-bad-checksum {
    color: red;
  }
  .vin-chars-c {
    display: flex;
    justify-content: space-between;
    margin-top: 2px;
    .browse-link {
      text-align: right;
      font-size: 0.9rem;
    }
  }

  .condition-c {
    display: flex;
    justify-content: space-between;
    a {
      width: 33%;
      flex-grow: 1;
      flex-shrink: 1;
      background-color: #eee;
      text-align: center;
      margin: 0 5px;
      border-radius: 5px;
      padding: 5px;
      color: #111;
    }
    a:first-child {
      margin-left: 0;
    }
    a:last-child {
      margin-right: 0;
    }
    a.selected, a.selected:hover {
      background-color: $medium-dark-color;
      color: #fff;
    }
    a:hover {
      text-decoration: none;
      background-color: #888;
      color: #fff;
    }
  }
  .condition-desc {
    margin-top: 5px;
  }

  .drilldown-header {
    display: flex;
    justify-content: space-between;
  }

  .found-at-auction {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0;
    padding: 8px 10px;
    border-radius: 10px;
    background-color: #eee;
    font-weight: bold;
  }
}


// Valuations no accessing
.valuations-no-access-body-c {
  margin-left: $primary-nav-width;
  padding: $standard-margin;
}



// Vehicles home
.no-vehicles-home {
  section {
    margin-bottom: 60px;
  }
  .get-started {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    gap: 20px;
  }
  .get-started > a {
    color: $default-text-color;
  }
  .get-started > a:hover {
    background-color: #eee !important;
    text-decoration: none;
  }
  .get-started > a, .get-started > div {
    flex-grow: 1;
    flex-shrink: 1;
    width: 33%;
    border-radius: 10px;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 4px 16px rgba(0,0,0,0.1);
    position: relative;
    overflow: hidden;
    min-height: 200px;
  }
  .inline-vin-entry {
    width: 100%;
    min-width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    color: #fff;
    margin-top: 10px;
    input.vin {
      font-size: 1.2rem;
    }
  }

}

.inline-vin-entry {
  max-width: 380px;
  flex-grow: 1;
  flex-shrink: 1;

  input.vin {
    font-size: 1rem;
    color: $default-text-color;
    padding: 5px 10px;
    border-radius: 4px;
  }

  input.vin-bad-checksum {
    color: red;
  }

  .vin-chars-c {
    min-height: 30px;
  }
}

.folders-kanban-rows {
  margin-bottom: 5rem;
  padding: 0 20px 20px 20px;
  .droppable {
    background-color: $highlight-color !important;
  }
  .droppable-active {
    background-color: $blue-accent-color !important;
    color: #fff !important;
  }
}

.kanban-row {
  border-radius: 8px;
  border: 1px solid #ddd;
  background-color: #f4f4f4 !important;
  padding: 10px;
  margin-right: 8px;
  margin-bottom: 20px;
  color: #111;
  transition: background-color 0.3s ease;
  img {
    width: 26px;
    height: 22px;
    opacity: 0.5;
  }

  .kanban-items-c {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: stretch !important;
    a {
      width: 250px;
      min-height: 70px;
      border-radius: 5px;
      border: 1px solid #fff;
      margin-right: 5px;
      margin-top: 5px;
      padding: 5px;
      background-color: #fff;
      box-shadow: 0 2px 8px rgba(0,0,0,0.1);
      display: block;
      color: #111 !important;
    }
    a:hover {
      text-decoration: none;
      background-color: #fff;
      border: 1px solid #aaa;
      box-shadow: 0 6px 18px rgba(0,0,0,0.1);
    }
  }

  h2 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: bold;
    padding-bottom: 8px;
    letter-spacing: -0.5px;
    svg {
      margin-right: 10px;
    }
    a {
      color: #111 !important;
    }
    a:hover {
      color: #111 !important;
      text-decoration: none;
    }
  }


  .kanban-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .kanban-details {
      flex-grow: 1;
      flex-shrink: 1;
      .year {
        font-size: 0.9rem;
        font-weight: bold;
        color: #aaa;
      }
      .title {
        font-weight: bold;
        margin: 0;
        font-size: 1.1rem;
        line-height: 1.1rem;
      }
      .details {
        font-size: 0.9rem;
      }
    }
    .kanban-notes {
      flex-grow: 0;
      flex-shrink: 0;
      width: 36px;
      text-align: right;
      font-size: 0.8rem;
      color: $light-color;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      img {
        width: 12px;
        height: auto;
        margin-left: 2px;
        opacity: 0.5;
        box-shadow: 0px 2px 2px rgba(0,0,0,0.4);
      }
    }

  }
}

.folder-reorder-c {
  min-height: 250px;
  max-height: 450px;
  overflow: scroll;

  padding: 0;
  border: 0;
  .folder {
    cursor: grabbing;
    font-size: 1.1rem;
    font-weight: bold;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #eee;
    margin-bottom: 5px;
  }
  .dragged {
    background-color: $blue-accent-color;
    color: #fff;
  }
}


// Manheim

.manheim-c {
  display: block;
  .trend-graph {
    height: 160px;
    background-color: #fff;
  }
  .trend-graph-info {
    margin-top: 10px;
  }
}


// Pipeline

.pipeline-c {
  display: block;
  .header-values {
    @media screen and (max-width: $break-extension) {
      display: block;
      width: 100%;
    }
  }
  .pipeline-header-value-c {
    position: relative;
    background-color: #005cb2 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: $break-extension) {
      display: block !important;
      width: 100% !important;
      margin: 6px 0 !important;
    }
  }
  .header-value-value {
    font-size: 2.5rem !important;
    margin-bottom: 10px;
  }
  .header-value-label {
    line-height: 1.2rem;
  }
  .adjusted-condition-c {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    background-color: #226db3;
    border-radius: 8px;
    padding: 5px 10px;
    @media screen and (max-width: $break-large) {
      display: block;
    }

  }
  .adjusted-condition-current-value {
    font-weight: bold;
    font-size: 1.1rem;
    background-color: #005cb2;
    padding: 2px 6px;
    border-radius: 6px;
  }
  .adjusted-condition-separator {
    border-top: 2px solid #3e78ae;
    margin: 0 12px;
    flex-grow: 1;
    flex-shrink: 1;
  }
  .adjusted-condition-label {
    font-size: 13px;
    color: #8fc0ee;
  }
  .trend-graph {
    height: 160px;
    background-color: #fff;
  }
  .trend-graph-info {
    margin-top: 10px;
  }
}

// Universe
.universe-cpo-toggle {
  a {
    color: $blue-accent-color !important;
  }
}

.universe-data-table {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: $break-extension) {
    display: block;
  }
  .overview, .history-adjusted {
    flex-grow: 1;
    flex-shrink: 1;
    @media screen and (max-width: $break-extension) {
      margin-top: 20px;
    }
  }
  .overview {
    margin-right: $standard-margin;
    @media screen and (max-width: $break-extension) {
      margin-right: 0;
    }
  }
  .universe-row {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: $break-extension) {
      border-bottom: 1px solid #ddd;
    }
    div:first-child {
      font-weight: bold;
    }
  }
}

.universe-trims-c {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: $break-extension) {
    display: block;
  }
  .universe-trim-table {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    @media screen and (max-width: $break-extension) {
      width: 100% !important;
      margin: 0 !important;
      margin-top: 5px !important;
    }
  }
  .universe-trim-header {
    font-weight: bold;
    text-align: center;
    background-color: $medium-dark-color;
    padding: 5px 0;
    color: #fff;
    font-size: 1.2rem;
    .universe-trim-titles {
      font-size: 0.8rem;
      font-weight: normal;
    }
  }
  .universe-trim-body {
    font-size: 0.9rem;
    padding: 10px 0;
  }
  .universe-trim-cols, .universe-trim-titles {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
    .trim {
      flex-grow: 1;
      flex-shrink: 1;
      margin-right: 5px;
      padding-left: 8px;
    }
    .transactions {
      flex-grow: 0;
      flex-shrink: 0;
      width: 40px;
      text-align: center;
    }
    .price {
      flex-grow: 0;
      flex-shrink: 0;
      width: 76px;
      text-align: right;
      padding-right: 8px;
    }
  }
  .universe-trim-cols:hover {
    background-color: #eee;
  }
}


// LLM
.llm-summary {
  margin-top: 5px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  min-height: 200px;
  @media screen and (max-width: 1600px) {
    display: block;
  }
}
.llm-summary-values-c {
  flex-grow: 0;
  flex-shrink: 0;
  width: 210px;
  background-color: #eee;
  border-radius: 10px;
  padding: 10px;

  @media screen and (max-width: 1700px) {
    width: 200px;
  }
  @media screen and (max-width: 1600px) {
    width: 100%;
    margin-bottom: 10px;
  }
  .llm-summary-values {
    > div {
      margin-bottom: 10px;
      @media screen and (max-width: 1600px) {
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      @media screen and (max-width: $break-extension) {
        flex-wrap: wrap;
      }
      > div {
        @media screen and (max-width: $break-extension) {
          margin-bottom: 10px;
        }
      }
    }
    @media screen and (max-width: 1600px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;

      gap: 10px;
      > div {
        margin-bottom: 0;
      }
    }
  }
}

.llm-summary-graph-map {
  width: 100%;
  min-width: 0;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 10px;
  align-content: stretch;
  @media screen and (max-width: $break-extension) {
    display: block;
  }
}

.llm-summary-graph {
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  min-height: 280px;
  min-width: 0;
  background-color: #eee;
  border-radius: 10px;
  @media screen and (max-width: 1500px) {
    width: 50%;
    min-height: 240px;
    max-height: 260px;
  }
}

.llm-summary-trims-c {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 440px;
  padding: 10px;
  min-height: 280px;
  min-width: 0;
  background-color: #eee;
  border-radius: 10px;

  @media screen and (max-width: $break-extension) {
    min-height: inherit;
  }
}


.llm-summary-trims-list {
  a.trim-row {
    display: block;
    position: relative;
    margin-bottom: 5px;
    background-color: #ddd;
    padding: 5px 8px;
    border-radius: 6px;
    overflow-x: hidden;
    color: #111;
    .trim-details-c {
      position: relative;
      padding-right: 30px;
    }
    .trim-name {
      font-weight: bold;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .trim-avg {
      color: rgba($color: #000000, $alpha: 0.4);
      margin-top: -5px;
    }
    .trim-count {
      position: absolute;
      top: 50%;
      margin-top: -15px;
      right: 0px;
      width: 30px;
      height: 30px;
      background-color: rgba($color: #fff, $alpha: 0.75);
      border-radius: 15px;
      line-height: 30px;
      font-size: 0.88rem;
      text-align: center;
      color: #111 !important;

    }
    .trim-row-bar {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color: #d0d0d0;
    }
  }
  a.trim-row:hover {
    text-decoration: none;
    background-color: rgb(203, 203, 203);
    .trim-row-bar {
      background-color: #b3b3b3;
    }
  }
  a.trim-row-selected, a.trim-row-selected:hover {
    display: block;
    background-color: #ee7a7a;
    color: #fff !important;
    cursor: default;
    .trim-row-bar {
      background-color: #e95656;
    }
    .trim-avg {
      color: rgba($color: #fff, $alpha: 0.6) !important;
      margin-top: -5px;
    }
    .trim-count {
      background-color: rgba($color: #fff, $alpha: 0.9);
    }
  }
}



.llm-summary-map-c {
  width: 45%;
  height: 100%;
  min-height: 1;
  flex-grow: 1;
  flex-shrink: 1;
  @media screen and (max-width: 2200px) {
    width: 400px;
  }
  @media screen and (max-width: 2000px) {
    width: 340px;
  }
  @media screen and (max-width: 1900px) {
    width: 250px;
  }
  @media screen and (max-width: 1600px) {
    width: 40%;
    min-height: 260px !important;
    height: 300px;
    min-height: 1;
  }
  @media screen and (max-width: $break-extension) {
    width: 100%;
    margin-top: 10px;
  }
}

.llm-summary-map-c-wide {
  width: 100% !important;
  min-height: 1;
  height: 280px;
  @media screen and (max-width: 1500px) {
    height: 280px;
  }
}

.trend-graph {
  height: 160px;
  background-color: #fff;
}

.llm-sample-detail-body {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: $break-extension) {
    display: block;
  }
}

.llm-sample-detail-prices {
  width: 280px;
  max-height: 380px;
  overflow-y: auto;
  overflow-x: visible;
  border-left: 1px solid #ddd;
  padding-left: 10px;
  margin-left: 20px;

  @media screen and (max-width: $break-extension) {
    width: 100%;
    padding-left: 5px;
    margin-left: 0;
    margin-top: 20px;
  }
}


// NADA

.nada-capsules {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  @media screen and (max-width: 1300px) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: $break-extension) {
    display: block;
    width: 100%;
  }
}
.nada-capsule {
  background-color: $medium-dark-color;
  border-radius: 30px;
  font-size: 1.3rem;
  color: #fff;
  padding: 3px;
  display: flex;
  margin-right: 10px;
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 240px;
  @media screen and (max-width: 1500px) {
    font-size: 1rem;
  }
  @media screen and (max-width: 1300px) {
    margin-bottom: 10px;
  }
  @media screen and (max-width: $break-extension) {
    max-width: 100%;
    margin-right: 0;
  }
  .nada-capsule-label {
    width: 100px;
    text-align: center;
    font-weight: bold;
    @media screen and (max-width: 1500px) {
      width: 76px;
    }
  }
  .nada-capsule-value {
    flex-grow: 1;
    flex-shrink: 1;
    background-color: #eee;
    color: #111;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    padding-left: 10px;
    font-weight: bold;
  }
}

.nada-capsule:last-child {
  margin-right: 0;
}

.nada-trade-auction-c {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: $break-small) {
    display: block;
    div {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
  .nada-trade-auction-row {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: $break-small) {
      margin-bottom: 20px;
      font-size: 0.9rem;
    }
  }
}

.nada-trade-auction-c > div {
  flex-grow: 1;
  flex-shrink: 1;


  h3 {
    border-bottom: 2px solid #ddd;
  }
}



// kBB

.kbb-table {
  margin: 30px 0;
}

.kbb-section-title-c {
  margin-top: 10px;
  padding: 3px 0;
  border-bottom: 2px solid #ddd;
}

.kbb-section-title {
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}

.kbb-section-table {
  font-size: 1.1rem;
  padding-bottom: 20px;
}

.kbb-section-table-row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
}

.kbb-section-table-row-highlighted {
  background-color: $medium-dark-color;
  color: #fff;
  padding: 1px 5px;
  border-radius: 4px;
}

.kbb-lending-c {
  display: flex;
  justify-content: space-between;
  font-size: 1.3rem;
  @media screen and (max-width: $break-extension) {
    display: block;
  }
  span {
    font-weight: bold;
  }
}

.kbb-lending-c div {
  padding: 5px 10px;
  background-color: #53565f;
  flex-grow: 1;
  flex-shrink: 1;
  color: #fff;
}

.kbb-lending-c div:first-child {
  background-color: $medium-dark-color;
  color: #fff;
  width: 40%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  @media screen and (max-width: $break-extension) {
    width: 100%;
    border-radius: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}
.kbb-lending-c div:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  @media screen and (max-width: $break-extension) {
    border-radius: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}




// PIN
.distributions-c {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
  @media screen and (max-width: $break-medium) {
    display: block;
  }
  .distribution-col {
    width: 48%;
    @media screen and (max-width: $break-medium) {
      width: 100%;
    }
  }
  .distribution-header-value {
    font-size: 2.1rem;
    letter-spacing: -1px;
    font-weight: bold;
  }
}

.distributions-c > div {
  flex-grow: 1;
  flex-shrink: 1;
  @media screen and (max-width: $break-medium) {
    margin-bottom: 40px;
  }
}

.distributions-c > div:nth-child(2) {
  margin-left: $standard-margin * 2;
  @media screen and (max-width: $break-medium) {
    margin-left: 0;
  }
}


// Carfax
.carfax-snapshot {
  margin-top: 25px;
  font-weight: bold;
  display: flex;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
    margin-left: 10px;
  }
}
.carfax-connect-preview-data {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  div.carfax-preview-item {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
    .carfax-preview-text {
      text-align: center;
      line-height: 1rem;
      font-size: 0.95rem;
    }
    img {
      display: block;
      width: auto;
      height: 44px;
      margin-bottom: 5px;
    }
  }

}
.carfax-preview-data {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media screen and (max-width: $break-extension) {
    align-items: stretch;
  }
  justify-content: space-between;
  margin-top: 10px;
  div.carfax-preview-item {
    flex-grow: 1;
    flex-shrink: 1;
    width: 30%;
    margin-right: 10px;
    display: flex;
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 6px 10px;
    font-weight: bold;
    background-color: #eee;

    div:nth-child(1) {
      flex-grow: 1;
      flex-shrink: 1;
    }
    div:nth-child(2) {
      flex-grow: 0;
      flex-shrink: 0;
      width: 34px;
      text-align: center;
    }
  }
  div.carfax-preview-item:last-child {
    margin-right: 0;
  }
  div.carfax-preview-item-warning {
    background-color: #f9dada;
  }
  img {
    width: 24px;
    height: 24px;
  }
}

.full-report-link {
  text-align: center;
  margin: $standard-margin 0 10px 0;
  a {
    color: $blue-accent-color;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.1rem;
  }
}

.carfax-full-report-c {
  padding: $standard-margin 0;
}

.shared-carfax {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 6px solid #ddd;
  @media screen and (max-width: 1200px) {
    padding-left: 10px;
  }
}

.autocheck-preview-data {
  margin-top: 10px;
}

.autocheck-section {
  margin-bottom: 20px;
  h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
    border-bottom: 1px solid #888;
  }
}


// Provider common
.header-values {
  display: flex;
  justify-content: space-between;
  margin-top: $standard-margin;
  .header-value-value {
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 2rem;
    @media screen and (max-width: $break-extension) {
      font-size: 1.6rem;
      letter-spacing: 0;
    }
    @media screen and (max-width: $break-small) {
      font-size: 1.4rem;
      letter-spacing: 0;
    }
  }
  .header-value-label {
    font-size: 1rem;
    color: #ccd1e1;
    @media screen and (max-width: $break-extension) {
      font-size: 0.9rem;
    }
  }
}

.header-values .header-value-c {
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;
  background-color: $medium-dark-color;
  box-shadow: 0 5px 10px rgba(0,0,0,0.3);
  border-radius: 8px;
  overflow: visible;
  padding: 10px;
  margin-top: 0 !important;
  margin-left: 3px;
  margin-right: 3px;
  color: #fff;
}

.header-values .header-value-c:first-child {
  margin-left: 0;
}
.header-values .header-value-c:last-child {
  margin-right: 0;
}

.graph-tooltip {
  background-color: #fff;
  border: 1px solid #aaa;
  box-shadow: 0 8px 30px rgba(0,0,0,0.5);
  font-size: 0.9rem;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
}

.graph-x-axis {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ddd;
  font-weight: bold;
  font-size: 0.9rem;
}


.prices-table {
  width: 100%;
  .prices-table-row {
    opacity: 0.8;
    background-color: #eee;
    display: flex;
    align-items: center;
    margin-bottom: 1px;
    font-size: 1.1rem;
  }
  .prices-table-row-header {
    background-color: #fff;
  }
  .prices-table-row:first-child {
    @media screen and (max-width: $break-small) {
      font-size: 0.8rem;
    }
  }
  .prices-table-cell {
    width: 25%;
    text-align: center;
    @media screen and (max-width: $break-small) {
      font-size: 0.9rem;
    }
  }
  .prices-table-cell-6 {
    width: 16.666%;
    text-align: center;
    @media screen and (max-width: $break-extension) {
      font-size: 0.88rem;
    }
  }


  .prices-table-cell-separator {
    border-right: 3px solid #fff;
    background-image: url('../assets/images/spread-arrow.png');
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: 17px 15px;
    @media screen and (max-width: $break-extension) {
      background: none;
      border-right: none;
    }
  }
  .row-label {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 6px 10px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $medium-dark-color;
    width: 25% !important;
    text-align: left;
    .stars {
      font-size: 0.8rem;
      opacity: 0.5;
    }
  }

  .row-label-6 {
    width: 16.666% !important;
    @media screen and (max-width: $break-extension) {
      font-size: 0.8rem;
    }
  }
  .prices-table-row-highlighted {
    opacity: 1;
    background-color: #ddd;
    font-weight: bold;
    font-size: 1.2rem;
    .stars {
      opacity: 1;
    }
  }

  .header-cell {
    background-color: #fff;
    font-size: 0.9rem;
    font-weight: bold;
    @media screen and (max-width: $break-small) {
      font-size: 0.8rem;
    }
  }

}



// Upsell

.upsell-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
  margin: 10px 40px;
  column-gap: 30px;
}

a.upsell-c {
  @include flex-row();
  align-items: flex-start;
  margin: 20px;
  margin-left: 40px;
  border: 1px solid #aaa;
  padding: 10px;
  border-radius: 8px;
  color: #222;
  h2 {
    font-size: 1.4rem;
    letter-spacing: -0.5px;
  }
  svg {
    margin-right: 5px;
  }
  @media print {
    display: none;
  }
}

a.upsell-c:hover {
  text-decoration: none;
  background-color: #eee;
}

.data-preview-message {
  outline: 3px solid #eee;
  border: 4px solid #fff;
  background-color: #eee;
  border-radius: 8px;
  padding: 10px 14px;
  color: #111;
  font-size: 1.1rem;
  margin-top: 10px;
  margin-left: 4px;
  margin-right: 4px;
  a {

    font-weight: bold;
    color: #000;

    text-decoration: underline;
  }
}



// Folder assignment
.folder-assignment-icon {
  width: 13px;
  height: auto;
  margin-right: 4px;
}

.folder-assignment-folder-c {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .details {
    flex-grow: 1;
    flex-shrink: 1;
  }
  .icon {
    flex-grow: 0;
    flex-shrink: 0;
    width: 30px;
    font-size: 1.5rem;
    text-align: right;
  }
}

// Drilldown
.drilldown-c > div {
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  margin: 0 5px;
  input {
    height: 100% !important;
    margin: 5px 0;
  }
}


// Search
.search-controls-c {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.search-controls-c {
  > div {
    flex-grow: 1;
    flex-shrink: 1;
    width: 25%;
    margin: 0 5px;
  }
  input.text-input {
    margin: 0;
    padding: 8.5px;
    transition: all 100ms;
    border-radius: 4px;
    border-color: hsl(0,0%,80%);
    &:hover {
      border-color: hsl(0,0%,70%);
    }
    &:focus {
      border-color: #2684FF;
      box-shadow: 0 0 0 1px #2684FF;
    }
  }
}

.react-select {
  input {
    height: 100% !important;
    margin: 5px 0;
  }
}

.search-controls-c > div {
  flex-grow: 1;
  flex-shrink: 1;
}

.search-controls-c > div:first-child {
  margin-left: 0;
}

.search-controls-c > div:last-child {
  margin-right: 0;
}

.search-results {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #ddd;
}


// Settings
.settings-nav {
  padding: 20px;
  text-align: center;
  .user-details {
    margin-right: 10px;
    .avatar {
      margin: 0 auto;
      box-shadow: 0 4px 30px rgba(0,0,0,0.6);
    }
    h2 {
      color: #fff;
      font-size: 1.6rem;
      letter-spacing: -0.5px;
      margin-top: 10px;
    }
  }
}
.settings-notification {
  background-color: $warning-yellow-color;
  border-radius: $border-radius;
  padding: 5px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 1.1rem;
  margin-bottom: 10px;
}


.settings-content-c {
  section {
    margin-bottom: $standard-margin;
    padding: $standard-margin;
    overflow: inherit;
    h2 {
      margin-bottom: 10px;
    }
    h3 {
      font-weight: bold;
      font-size: 1.2rem;;
    }
    form {
      padding: 0;
      margin: 0;
    }
    .btn {
      width: 250px;
      flex-grow: 0;
      flex-shrink: 0;
      margin: 0 5px;
    }
    input[type="checkbox"] {
      display: inline !important;
      flex-grow: 0;
      flex-shrink: 0;
      width: auto;
    }
  }
}

.settings-add-user-type-button {
  text-align: left;
  background-color: #eee;
  color: #111;
  margin-bottom: 10px;
  width: 100%;
  box-shadow: 0 3px 6px rgba(0,0,0,0.2);
  outline: 0;
  border: 1px solid #ddd;
  * {
    font-weight: normal;
  }
}

.settings-add-user-type-button:focus {
  outline: 0 !important;
}

.account-body-c {
  margin-left: $primary-nav-width + 20px;
  padding: $standard-margin;
  padding-right: $standard-margin + 10px;
}

.account-action-page-header {
  min-height: inherit;
  border: 2px solid #ddd;
  background-color: #fff;
  padding: 10px;
  margin-left: $standard-margin * 2;
  margin-right: $standard-margin;
  margin-top: 10px;
  border-radius: 6px;
}

.account-action-warn {
  background-color: $accent-color;
  color: #fff;
  padding: 10px 20px;
  overflow: hidden;
  border: none;
  h1 {
    color: #fff;
    font-size: 1.6rem;
    margin: 0;
    padding: 0;
  }
  a {
    color: #fff;
    text-decoration: underline;
  }
}

.account-state-message {
  color: red;
}

.account-payment-form {
  button {
    width: 160px !important;
  }
  @media screen and (max-width: 1000px) {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
  form {
    color: green !important;
    display: flex;
    @media screen and (max-width: 1000px) {
      display: block;
      h3 {
        margin-bottom: 5px;
      }
    }
    button {
      margin-left: 0;
      padding-top: 4px;
      padding-bottom: 4px;
      flex-grow: 0;
      flex-shrink: 0;
      @media screen and (max-width: 1000px) {
        margin: 0;
        display: block;
        min-width: 100%;
        width: 100%;
        margin-top: 5px;
      }
    }
  }
}

.account-success {
  color: $green-accent-color;
  text-align: center;
  margin-top: 10px;
  font-size: 1.1rem;
}

.account-error {
  color: red;
  text-align: center;
  margin-top: 10px;
  font-size: 1.1rem;
}

a.account-add-offering {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #fff;
  color: $default-text-color;
  border: 1px solid #ddd;
  border-radius: 8px;
  h3 {
    font-weight: bold;
    font-size: 1.2rem;
  }
  svg.selected {
    fill: $blue-accent-color !important;
  }
}

a.account-add-offering-selected {
  border: 1px solid $blue-accent-color;
}

a.account-add-offering:hover {
  text-decoration: none;
  background-color: #eee;
}


.mobile-payment-form-c {
  background-color: rgba(255, 255, 255, 0.92);
  margin: 10px;
  padding: 10px;
  border-radius: 6px;
}

.mobile-payment-form-contact {
  text-align: center;
  margin: 10px;
  color: #fff;
}


.provider-order-provider {
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 4px;
  cursor: grabbing;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.provider-order-provider-disabled {
  opacity: 0.5;
}

.provider-order-dragged {
  background-color: $blue-accent-color;
  color: #fff;
}


// Auctions
.global-filters-indicator {
  background-color: #313236;
  padding: 2px 6px;
  color: #fff !important;
  font-weight: bold !important;
  margin-right: 10px;
  border-radius: 4px !important;
}

.global-filters-indicator:hover {
  background-color: #242528;
  text-decoration: none !important;
}

.global-filters-indicator-on {
  background-color: $accent-color;
}

.global-filters-indicator-on:hover {
  background-color: $accent-color-highlight;
}

.saved-search-item {
  font-size: 0.9rem;
  .saved-search-owner {
    margin-right: 10px;
    margin-top: -2px;
    color: rgba(255, 255, 255, 0.3);
    text-shadow: none !important;
  }
}

.team-saved-search-item {
  margin-bottom: 10px;
}

.saved-search-item a.active .saved-search-owner {
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.5);
}

.new-count-indicator {
  background-color: #313236;
  padding: 2px 6px;
  color: #fff !important;
  margin-right: 10px;
  border-radius: 4px !important;
  font-size: 0.8rem;
}

.home-auctions-map-c {
  position: relative;
  background-color: #eee;
  background-color: #c9e9f2;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(175,175,180,0.3);
  margin-top: 10px;
  overflow: hidden;
}
.auctions-home-my-auctions-c {
  z-index: 999;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 10px;
  background-color: rgba(170, 211, 223, 0.7);
  backdrop-filter: blur(1rem);
  border-left: 1px solid rgba($color: #000000, $alpha: 0.2);

  ::-webkit-scrollbar {
    height: 12px;
    width: 12px;
    background: rgba(0, 0, 0, 0.2);
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #ddd;
    -webkit-border-radius: 1ex;
  }

  ::-webkit-scrollbar-corner {
    background: #000;
  }

}
.auctions-home-my-auctions {
  height: 100%;
  overflow-y: auto;
}

.auctions-home-my-auctions .auction {
  border-bottom: 1px solid #bbb;
  padding: 5px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 5px;
  .secondary-text {
    line-height: 1rem;
  }
  a {
    color: $default-text-color;
  }
}

.auction-map-popup {
  font-size: 1rem;
}

.auctions-edge-digital-banner {
  display: block;
  background-color: #8160E0;
  background: linear-gradient(-45deg, #8160E0, #a560e0);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  color: #fff;
  overflow: hidden;
  box-shadow: 0px 2px 6px rgba(175,175,180,0.3);
  position: relative;
  h3 {
    color: #fff !important;
    font-size: 1.2rem !important;
  }
}

.auctions-edge-digital-banner:hover {
  background: linear-gradient(-45deg, #795ad1, #9958cf);
  text-decoration: none;
  color: #fff;
}

.auctions-home-shortcuts {
  a {
    color: #888;
    border: 1px solid #aaa;
    padding: 2px 6px;
    border-radius: 6px;
    margin-right: 10px;
    svg {
      margin-top: -3px;
      margin-right: 5px;
    }
  }
  a:hover {
    text-decoration: none;
    background-color: $accent-color;
    border: 1px solid $accent-color;
    color: #fff;
  }
}

.auctions-home-features {
  margin: 20px 0;
  display: flex;
  color: #fff;
  gap: 10px;

  a:hover {
    text-decoration: none;
  }

  > .common-feature {
    border-radius: 10px;
    padding: 10px;
    color: #fff;
    overflow: hidden;
    box-shadow: 0px 2px 6px rgba(175,175,180,0.3);
    position: relative;
    background-color: #607CE0;
    background: linear-gradient(-45deg, #23a6d5, #21cbba);
    a {
      color: #fff
    }
    a:hover {
      text-decoration: underline;
    }
    .global-filters-indicator {
      background-color: rgba(0, 0, 0, 0.1);
      margin-left: 5px;
    }
    a.global-filters-indicator:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  > a.common-feature:hover {
    background: linear-gradient(-45deg, #2099c4, #1ebaaa);

  }

  .global-filters {
    a {
      display: flex;
      flex-direction: row;
      .icon {
        margin-right: 6px;
        width: 28px !important;
        height: 28px !important;
      }
      .global-filters-indicator {
        margin-left: 8px;
      }
    }
  }

  .profit-enable {
    flex-basis: 100%;
    border-radius: 10px;
    padding: 10px;
    color: #fff;
    overflow: hidden;
    box-shadow: 0px 2px 6px rgba(175,175,180,0.3);
    position: relative;
    margin-right: 0;
    background-color: $accent-color;
    background: linear-gradient(-45deg, #e73c7e, #ee6452);
  }

  h3 {
    color: #fff !important;
    opacity: 0.8;
  }
}

h3.subsection-title {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 1rem;
  @media screen and (max-width: $break-extension) {
    font-size: 1rem;
  }
}

.auction-listing-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px 20px;

  .auction-listing-body {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;

    .thumbnail {
      width: 120px !important;
      height: 90px !important;
      flex-shrink: 0;
      flex-grow: 0;
      margin-right: 10px;
      position: relative;
      overflow: hidden;
      img {
        width: 120px !important;
        height: auto !important;
        border-radius: 4px;
      }

      @media screen and (max-width: 1700px) {
        width: 90px !important;
        height: 61px !important;
        img {
          width: 90px !important;
        }
      }

      .overlay {
        position: absolute;
        padding: 2px 5px;
        background-color: rgba($color: #000000, $alpha: 0.4);
        color: #fff;
        text-align: center;
        font-size: 0.9rem;
        line-height: 0.9rem;
        border-radius: 4px;
      }
      .run-number {
        right: 3px;
        bottom: 3px;
      }
      .lane-number {
        right: 3px;
        top: 3px;
      }
      .team-watchers {
        position: absolute;
        display: flex;
        left: 3px;
        top: 3px;
        background-color: transparent !important;
        > div {
          margin-right: -14px;
        }
      }
    }

    .listing-specs {
      width: 100%;
      h3 {
        font-size: 1.2rem;
        font-weight: bold;
      }
      h3.year {
        font-size: 1rem;
        font-weight: bold !important;
        color: #aaa;
      }

      .listing-details {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-grow: 1;
        flex-shrink: 1;
        div {
          flex-grow: 1;
          flex-shrink: 1;
        }
        .target-price {
          width: 110px;
          flex-grow: 0;
          flex-shrink: 0;
          svg {
            margin-top: -3px;
            margin-right: 3px;
          }
        }
        .cr-location {
          width: 270px;
          flex-grow: 0;
          flex-shrink: 0;
          padding-right: 41px;
          .channel {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          @media screen and (max-width: 1700px) {
            width: 200px;
          }
        }
        .estimates {
          width: 188px;
          flex-grow: 0;
          flex-shrink: 0;
          > div {
            display: flex;
            flex-direction: flex-start;
            align-items: center;
            div:first-child {
              flex-grow: 0;
              flex-shrink: 0;
              width: 110px;
              text-align: right;
              padding-right: 6px;
              font-size: 0.9rem;
            }
            div:last-child {
              font-weight: bold;
            }
          }
        }

      }
    }

  }

  .auction-listing-body-with-bidding {
    .mileage {
      width: 94px !important;
    }
    .condition {
      width: 50px !important;
    }
  }

  .end-controls {
    align-self: stretch;
    flex-grow: 0;
    flex-shrink: 0;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;


    .watchlist {
      background: none;
      border: none;
      outline: none;
      svg {
        opacity: 0.25;
      }
      svg:hover {
        opacity: 0.5;
      }
    }
    .watchlist-active {
      svg {
        opacity: 1.0;
      }
    }
    .watchlist:hover {
      svg {
        opacity: 0.5;
      }
    }
    .watchlist-active:hover {
      svg {
        opacity: 1.0;
      }
    }

    .notes {
      position: absolute;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: #fff;
      border-radius: 15px;
      border: 1px solid #aaa;
    }

  }
}



.auction-listing-cell {
  width: 220px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 20px;
  box-shadow: 0 3px 16px rgba(0,0,0,0.12);
  a.listing-link {
    display: flex;
    flex-direction: column;
    height: 100%;
  }


  .thumbnail {
    height: 163px;
    background-color: #fff;
    overflow: hidden;
    position: relative;
    .overlay {
      position: absolute;
      padding: 2px 5px;
      background-color: rgba($color: #000000, $alpha: 0.4);
      color: #fff;
      text-align: center;
      font-size: 0.9rem;
      line-height: 0.9rem;
      border-radius: 4px;
    }
    .run-number {
      right: 3px;
      bottom: 3px;
    }
    .lane-number {
      right: 3px;
      top: 3px;
    }
    .team-watchers {
      display: flex;
      left: 3px;
      top: 3px;
      background-color: transparent !important;
      > div {
        margin-right: -14px;
      }
    }
  }
  .thumbnail, .thumbnail img {
    width: 100%;
  }

  .no-image {
    width: 100%;
    background-color: #ddd;
    height: 150px;
  }

  .listing-body-c {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
  }
  .listing-body {
    padding: 10px;
    font-size: 1rem;
    h3 {
      font-size: 1.2rem;
      font-weight: bold;
    }
    h3.year {
      font-size: 1rem;
      font-weight: bold !important;
      color: #aaa;
    }
    .channel {
      margin-top: 5px;
    }
  }

  .listing-pricing {
    background-color: #f8f8f8;
    background-color: #333;
    background-color: #f0f2f3;
    color: #fff;
    color: #111;
    padding: 2px 10px;
  }

  .watchlist {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    width: 32px;
    border: none;
    outline: none;
    padding: 0;
    background-color: transparent;

    svg {
      opacity: 0.25;
    }
    svg:hover {
      opacity: 0.5;
    }
    svg:focus {
      outline: none;
    border: none;
    }
  }
  .watchlist:focus, .watchlist:active {
    box-shadow: none !important;
    background-color: transparent !important;
  }

  .watchlist-active {
    svg {
      opacity: 1.0;
    }
  }
  .watchlist:hover {
    svg {
      opacity: 0.5;
    }
  }
  .watchlist-active:hover {
    svg {
      opacity: 1.0;
    }
  }
}

.auction-listings-display-bar-global-filters {
  a {
    display: flex;
    flex-direction: row;
    .icon {
      display: none;
    }
    .global-filters-indicator {
      margin-left: 8px;
    }
  }
}

.auction-listing-row-bidding-c {
  margin: 30px 0;
}

.watchlist-bounce {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-name: watchlist-bounce;
  animation-name: watchlist-bounce;
}

@keyframes watchlist-bounce {
  0% { transform: scale(1) }
  20% { transform: scale(0.8) }
  55% { transform: scale(1.3) }
  90% { transform: scale(0.8) }
  100% { transform: scale(1) }
}




.auctions-settings-location-row {
  padding: 10px 20px;
  margin-right: 0;
  width: 100%;
  a {
    width: 100%;;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.auction-listing-photos-placeholder-c {
  width: 100%;
  height: 400px;
  background-color: #eee;
}

.auction-listing-photos-c {
  width: 100%;
  height: 400px;
  @media screen and (max-width: $break-extension) {
    height: 300px;
  }
  overflow: hidden !important;
  animation-timing-function: ease-out;
  -webkit-animation: fadein 1s;
  -moz-animation: fadein 0.75s;
  -ms-animation: fadein 0.75s;
  animation: fadein 0.75s;

  .auction-listing-photo {
    height: 400px !important;
    width: auto !important;
    margin-right: 10px;
  }
  .slick-prev, .slick-next {
    z-index: 9999;
    opacity: 0.6;
  }
  .slick-prev:hover, .slick-next:hover {
    opacity: 1.0;
  }
  .slick-prev:before, .slick-next:before {
    position: absolute;
    top: 50%;
    color: #fff;
    font-size: 30px;
    margin-top: -15px;
  }
  .slick-prev:before {
    left: 40px;
  }
  .slick-next:before {
    right: 40px;
  }

  .slider {
    height: 300px !important;
  }

  .auction-listing-photo {
    color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@keyframes fadein {
  from { opacity: 0}
  to   { opacity: 1}
}



.auction-listing-body-content-embedded {
  position: relative;
  padding: 0;
}
.auction-listing-show {
  .content-header {
    margin-top: 20px;
    h1 {
      padding: 0;
      font-size: 2.4rem;
      @media screen and (max-width: $break-medium) {
        font-size: 1.9rem;
      }
    }
    h2 {
      margin-bottom: 5px;
      @media screen and (max-width: $break-medium) {
        font-size: 1.2rem;
      }
    }

    .target-price, a.watchlist {
      font-weight: bold;
      margin-left: 10px;
      padding: 10px 18px;
      border-radius: 22px;
      border: 1px solid #ddd;
    }

    .target-price {
      background-color: #eee;
      @media screen and (max-width: $break-medium) {
        font-size: 1.2rem;
      }
      @media screen and (max-width: $break-extension) {
        font-size: 1rem;
      }
    }
    a.watchlist {
      background-color: #eee;
      width: 184px;
      color: $default-text-color;
      svg {
        opacity: 0.3;
        width: 32px;
        height: 24px;
        margin-top: -4px;
        margin-right: 10px;
      }
    }


    a.watchlist:hover {
      text-decoration: none;
      background-color: $accent-color;
      color: #fff;
      border: 1px solid $accent-color;
    }

    a.watchlist-active {
      background-color: $medium-dark-color;
      border: 1px solid $medium-dark-color;
      color: #fff;
      svg {
        opacity: 1.0;
      }
    }
    .team-watchers {
      margin-top: 12px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      > div {
        margin-left: 5px;
      }
    }
  }
  .embedded-auction-header {
    display: block;
  }
  .body-content {
    // Valuation nested in an auction listing
    border-top: 2px solid $extra-light-color;
    padding: $standard-margin 0 0 0;
    min-height: 400px;
  }

  .color-indicator {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-right: 4px;
    display: inline-block;
    border: 1px solid #eee;
  }




  .auction-listing-body {
    display: flex;
    margin-top: 10px;
    align-items: stretch;

    @media screen and (max-width: $break-extension) {
      display: block;
      margin-top: $standard-margin;
    }
    .auction-essentials {
      width: 240px;
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 10px;
      padding: 10px;
      .auction-essentials-row {
        margin-bottom: 5px;
        display: flex;
        align-items: stretch;

        .essentials-indicator {
          width: 40px;
          flex-grow: 0;
          flex-shrink: 0;
          align-content: center;
          background-color: #111;
          border-radius: 6px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          color: #fff;
          font-size: 18px;
          line-height: 20px;
          text-align: center;
          font-weight: bold;
          letter-spacing: -0.6px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .essentials-label {
          flex-grow: 1;
          flex-shrink: 1;
          background-color: #f3f3f3;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
          padding: 6px 0;
          padding-left: 5px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          line-height: 1.1rem;
        }

        .essentials-indicator-warn {
          background-color: darkred;
        }

        .essentials-indicator-good {
          background-color: darkgreen;
        }
      }
    }
    .auction-body-vehicle-details {
      flex-grow: 1;
      flex-shrink: 1;
      padding-right: $standard-margin * 2;
      padding: 10px;
      margin-right: 10px;

      @media screen and (max-width: $break-extension) {
        padding-right: 0;
      }

      section {
        margin-bottom: $standard-margin;
        .section-title {
          font-size: 1.1rem;
          font-weight: bold;
          border-bottom: 1px solid #ddd;
          margin-top: 2px;
          @media screen and (max-width: $break-medium) {
            font-size: 1rem;
            margin-top: 4px;
          }
        }
      }
      .embedded-auction-header {
        display: block;
      }
      .vin {
        margin-top: 10px;
        margin-right: 20px;
        font-size: 1.2srem;
      }
      .salvage {
        background-color: red;
        padding: 4px 16px;
        margin-top: 10px;
        border-radius: 22px;
        color: #fff;
        font-weight: bold;
        font-size: 1.2rem;
      }
      .condition-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        div {
          margin-bottom: 5px;
        }
      }

    }

    .auction-location-details {
      width: 420px;
      flex-grow: 0;
      flex-shrink: 0;

      .auction-location-details-header {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        background-color: #607CE0;
        background: linear-gradient(-45deg, #23a6d5, #21cbba);
        padding: 10px;
        color: #fff;
      }

      @media screen and (max-width: $break-medium) {
        width: 280px;
      }
      @media screen and (max-width: $break-extension) {
        width: 100%;
      }

    }
  }
}

.auction-listing-smart-auction-cr-toggle:hover {
  text-decoration: none;
}

.auction-tires-table td, .auction-tires-table th {
  border-bottom: 1px solid #ddd;
}

.embedded-auction-modal-c .auction-body-vehicle-details {
  display: block !important;
}

.embedded-auction-modal-c .auction-body-auction-info {
  width: 300px !important;
}

.auction-lane-list-title {
  padding-left: 20px;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 0;
  letter-spacing: -1px;
  font-size: 1.7rem;
  line-height: 1.7rem;
  font-weight: bold;
}

.auction-sale-date {
  margin-bottom: 5px;
  a {
    background-color: transparent !important;
    display: inline !important;
    border: none !important;
    font-size: 0.9rem;
    font-weight: normal;
    color: $accent-color !important;
    padding: 0 10px;
  }
}

.auction-sale-events {
  font-weight: normal;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.auction-lane-sale {
  margin-left: 20px;
  margin-top: 40px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 1.1rem;
  color: $light-color;
  svg {
    margin-right: 10px;
  }
}

.auction-lane-sale:first-child {
  margin-top: 0;
}


.auction-lane-row {
  display: block !important;
  margin-right: 20px;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  .auction-lane-details-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    font-size: 1.2rem;
  }
}


// Auction listing modal
.listing-modal {
  width: 80vw;
  max-width: 1800px;
  height: 92vh;

  // overflow: scroll;
  .modal-c {
    max-height: 92vh;
    min-height: 92vh;
  }
  .modal-body {
    overflow: scroll;
    overflow-x: hidden;
  }
}

.auction-listing-modal-controls {
  top: -30px;
  left: 0;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  svg {
    margin-top: -2px;
    margin-right: 4px;
  }
  .listing-new-tab, .listing-close {
    flex-grow: 0;
    flex-shrink: 0;
    width: 170px;
  }
  .listing-close {
    width: 100px;
  }
  .listing-prev-next {
    flex: 1;
    a {
      width: 50%;
    }
    a:first-child {
      text-align: right;
    }
    a:last-child {
      text-align: left;
    }
  }

  a {
    color: #fff !important;
  }
  a:last-child {
    text-align: right;
  }
}


// Watchlist screen
.watchlist-sale-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #222;
  color: #fff;
  padding: 0 16px;
}


// Modal
.modal-bg {
  background-color: #000;
  opacity: 0.8 !important;
}

.modal-c {
  box-shadow: 0 8px 30px rgba(0,0,0,0.5);
}

.modal-header {
  background-color: $medium-dark-color;
  border-radius: 0.2rem 0.2rem 0 0;
  overflow: hidden;
  h5 {
    font-size: 2rem !important;
    margin: 0 !important;
    color: #fff;
    letter-spacing: -1px;
    @media screen and (max-width: $break-small) {
      font-size: 1.5rem !important;
    }
  }
}

.modal-controls {
  text-align: right;
  margin-top: $standard-margin * 3;
  display: flex;
  justify-content: space-between;
  button {
    flex-grow: 1;
    flex-shrink: 1;
    padding: 10px;
    width: 50%;
  }
  button:last-child {
    margin-left: 10px;
  }
}


// SpeedSearch
.speed-search-sidebar {
  overflow-y: auto;
  overflow-x: visible;
  position: fixed;
  height: 100%;
  padding: 0 15px;
  margin-left: -15px;
  section {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 6px;
    box-shadow: 0 3px 16px rgba(0,0,0,0.12);
  }
  a.expand:hover {
    text-decoration: none;
  }
  h3 {
    font-size: 1.1rem;
    font-weight: bold;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #aaa;
  }
  h3.contracted {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .slider {
    margin: 10px;
    margin-bottom: 20px;
  }
}

.speed-search-sidebar-collapsed {
  padding: 0 0;
  text-align: center;
  margin-left: -40px;
  a {
    color: $light-color;
    border-radius: 0 10px 10px 0;
    padding-top: 4px;
    margin-top: 5px;
    background-color: #111;
    opacity: 0.5;
  }
  a:hover {
    opacity: 1;
    color: #fff !important;
    div {
      color: #fff !important;
    }
  }
}

a.hide-filters {
  margin-bottom: 5px;
  svg {
    margin-right: 5px;
  }
}

a.hide-filters:hover {
  color: #111;
}

.speed-search-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
  margin-bottom: 10px;
  a {
    font-size: 1.3rem;
    margin-right: 20px;
  }
  a.active {
    color: #111;
    font-weight: bold;
  }
  a.active:hover {
    text-decoration: none;
  }
}

.auctions-searches-section {
  a {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
}


/* Global */

h1 {
  margin: 0 0 10px 0;
  font-size: 1.5rem;
  line-height: 2rem;
  overflow: visible;
  color: $extra-light-color;
  letter-spacing: -1px;
}

h2 {
  margin: 5px 0 0 0;
  font-size: 1.75rem;
  letter-spacing: -1.5px;
}

h3 {
  margin: 0;
  color: #424952;
  font-weight: normal;
  font-size: 0.9rem;
  letter-spacing: -0.5px;
}

.secondary-text, a.secondary-text {
  font-size: 0.9rem;
  color: $light-color;
  text-decoration: none;
}

.split-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.split-row-right {
  justify-content: flex-end;
}

.split-row-left {
  justify-content: flex-start;
}

.padded-row {
  padding: 10px 20px;
}

.tabbar {
  a {
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-radius: 6px 6px 0 0;
    margin-right: 10px;
    padding: 4px 10px;
    color: #666;
  }
  a.active {
    background-color: #eee;
    color: #000;
  }
  a:hover {
    text-decoration: none;
    background-color: #eee;
  }
}

.list-row-notes {
  width: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0.9rem;
  svg {
    margin-right: 4px;
    color: #ffe779;
  }
}

.tokenized-list-c {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
  .token {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;
    align-items: stretch;
    margin-right: 10px;

    .token-label {
      flex-grow: 1;
      flex-shrink: 1;
      background-color: #444;
      color: #fff;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      padding: 8px 12px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 1.1rem;
    }

    .token-action {
      flex-grow: 0;
      flex-shrink: 0;
      align-content: center;
      justify-content: center;
      background-color: #222;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      color: #fff;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
      font-weight: bold;
      letter-spacing: -0.6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 4px;
      svg {
        opacity: 0.4;
      }
    }
    .token-action:hover {
      background-color: $accent-color !important;
      svg {
        opacity: 1;
      }
    }
  }
}

.split-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.split-row-left {
  justify-content: flex-start;
}



// Auction Bidding
a.auctions-home-edge-feature {
  margin-top: 20px;
  background: url('../assets/images/edge-bidding-header-bg.png');
  background-size: cover;
  position: relative;
  overflow: hidden;
}

a.auctions-home-edge-feature {
  text-decoration: none;
}

.bidding-as {
  .bidding-as-name {
    color: #333;
    font-weight: normal !important;
    font-size: 0.9rem !important;
  }
  .dropdown-toggle {
    background-color: transparent !important;
    color: #000 !important;
    border: none;
    padding: 0;
    margin-top: -5px;
  }
  .dropdown-toggle:focus, .dropdown-toggle:active {
    outline: none !important;
    box-shadow: none !important;
  }
}

.bidding {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #fff;

  a {
    text-decoration: none !important;
  }

  .bid-status {
    color: #fff;
    padding: 3px 10px;
    border-radius: 6px;
    margin-left: -1px;
    margin-right: -1px;
    margin-top: -1px;

    &-high-bidder {
      background-color: green;
    }

    &-outbid {
      background-color: darkred;
    }

    svg {
      margin-left: 10px;
      margin-top: -2px;
    }
  }

  .bid-body {
    padding: 5px 10px 5px 10px;

    .bid-meta {
      font-size: 0.9rem;
      border-bottom: 1px solid #ddd;
      padding-bottom: 5px;
    }
    .current-bid {
      font-size: 1.75rem;
      font-weight: bold;
      margin-right: 5px;
    }
    .current-bid-label {
      margin-bottom: 4px;
    }
    .floor-met {
      margin-top: -5px;
      font-size: 0.85rem;
      color: green;
      svg {
        margin-bottom: 3px;
      }
    }

    a {
      color: $accent-color !important;
      text-decoration: underline;
    }
  }
}

.inline-bidding {
  margin-left: 10px;
  width: 360px;
}

.bid-actions button {
  font-weight: normal !important;
  font-size: 1rem !important;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
  transition: all .1s ease-in-out;
}

.bid-actions button.hard-bid {
  background-color: #264375 !important;
  border-color: #264375 !important;
}

.bid-actions button.hard-bid:active {
  background-color: $accent-color !important;
  border-color: $accent-color !important;
}

.bid-actions button.hard-bid:disabled {
  background-color: #264375 !important;
  border-color: #264375 !important;
  opacity: 0.2 !important;
}

.bid-actions .btn:focus, .bid-actions .btn:active {
  outline: none !important;
  box-shadow: none !important;
  color: #fff;
}

.bid-actions button.confirm {
  background-color: green;
}

.bid-actions button.cancel {
  background-color: #888;
}

.bid-error {
  color: $error-color;
}

.bidding-change-bounce {
  transform: scale(1.0);
  -webkit-transform: scale(1.0);
  -webkit-animation: bid-change-bounce 0.4s 1 ease-in-out;
  animation: bid-change-bounce 0.4s 1 ease-in-out;
}

@keyframes bid-change-bounce {
  0% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  } 50% {
    transform: scale(1.15);
    -webkit-transform: scale(1.15);
  } 100% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}


// Dashboard

.dashboard-body-c {
  margin-left: $primary-nav-width;
  padding-bottom: $standard-margin * 2;
}

.dashboard-cards-c {
  display: flex;
  flex-direction: row;
  .col {
    width: 33%;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0;
  }
  .col:first-child {
    width: 66%;
    flex-grow: 2;
    flex-shrink: 2;
    margin-right: 20px;
  }
}

.dashboard-card-carfax {
  background-color: #1e1b38;
  background-image: url('../assets/images/dash-card-abstract-1.jpg');
  background-size: cover;
  color: #fff;
  padding: 20px;
  h2 {
    font-weight: bold;
    font-size: 1.5rem;
    letter-spacing: -0.5px;
    margin: 0;
    margin-bottom: 30px;
    padding: 0;
  }
  .links-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  a.action {
    color: #fff;
    background-color: rgba(27, 22, 47, 0.75);
    border-radius: 20px;
    padding: 8px 20px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    svg {
      margin-right: 5px;
    }
  }
  a.hide {
    color: rgba(183, 167, 245, 0.75);
  }
}

.dashboard-card-auction-listings {
  .auction-listing-row {
    padding: 0;
    margin: 0;
    padding-right: 20px;
    background-color: #f9f9fb;
    border-top: 1px solid #dbdde3;
    border-bottom: none;
    h2 {
      font-size: 1.1rem;
      font-weight: normal;
      letter-spacing: 0;
    }
  }
  .auction-listing-row:hover {
    background-color: #fff;
  }
}

.dashboard-folders-c {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px;
  .dashboard-folder {
    margin-right: 20px;
    text-align: center;
    position: relative;
    svg {
      display: block;
      margin: 0 auto;
      color: #222;;
    }
    .vehicles-count {
      position: absolute;
      color: #fff;
      top: 10px;
      left: 0;
      width: 100%;
      font-size: 1.2rem;
      text-align: center;
    }
  }
}

.dashboard-card-mobile-app {
  background-color: #1e1b38;
  background-image: url('../assets/images/dash-card-mobile-bg.jpg');
  background-size: cover;
  background-position: 50% 75%;
  color: #fff;
  padding: 20px;
  h2 {
    font-weight: bold;
    font-size: 1.75rem;
    letter-spacing: -0.5px;
    margin: 0;
    margin-bottom: 30px;
    padding: 0;
  }
  .bottom-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    img.mobile-devices {
      width: 260px;
      height: 173px;
      margin-bottom: -20px;
    }
    .right-col {
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .store-links {
        height: 80px;
        a {
          opacity: 0.65;
          margin-left: 30px;
        }
        a:hover {
          opacity: 1.0
        }
        .apple {
          width: 40px;
          height: 48px;
        }
        .android {
          width: 42px;
          height: 49px;
        }
      }

    }
  }


  a.hide {
    color: rgba(145, 126, 126, 0.9);
  }
}



// Cards

.card {
  background-color: #fff;
  margin-bottom: 40px;
  border: 1px solid #e6e8ed;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(175,175,180,0.2);
  overflow: hidden; // Enforce border-radius clipping
  h2.card-title {
    padding-left: 20px;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 0;
    font-size: 1.2rem;
    letter-spacing: 0;
  }
  h2.card-title-with-control {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .card-title-control {
      margin-left: 40px;
      margin-right: 20px;
      font-size: 1rem;
      font-weight: normal;
    }
  }
  .card-actions {
    text-align: right;
    margin: 20px 20px 0 20px;
  }
  .card-list {
    .card-list-row {
      background-color: #f8f9fa;
      border-top: 1px solid #dbdde3;
      border-bottom: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-right: 20px;

      h3 {
        font-size: 1.3rem;
        font-weight: bold;
        letter-spacing: -0.75px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      }
    }
    .card-list-row:last-child {
      border-bottom: 1px solid #dbdde3;
    }
    .card-list-row:hover {
      background-color: #fff;
    }
    a {
      color: $default-text-color;
    }
    a:hover {
      text-decoration: none;
    }

  }
}



// Forms

form {
  text-align: left;
}

label {
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 0.9rem;
}

input, textarea, .date-picker-input {
  width: 100%;
  margin-bottom: $standard-margin;
  outline: none;
  box-sizing: border-box;
  border-style: solid;
  border: 1px solid #aaa;
  border-radius: $border-radius;
  padding: 10px;
  font-size: 1rem;
}

input[type="text"]:read-only {
  color: $light-color !important;
}

.date-picker-input {
  display: block;
  width: 150px;
  background-color: #fff;
  padding: 8px 10px;
  cursor: pointer;
  color: $default-text-color;
}

.inline-checkbox {
  label {
    font-size: inherit;
    font-weight: inherit;
    text-transform: none;
    line-height: inherit;
    color: $default-text-color;
  }
  a:last-child {
    margin-left: 10px;
  }
  a:last-child:before {
    content: '·';
    color: #aaa;
    padding-right: 10px;
  }
}

input.mileage {
  font-size: 17px !important;
}

.mileage-thousands {
  position: absolute;
  top: 14px;
  color: #888;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.action-link {
  text-decoration: none;
  color: $blue-accent-color;
  font-weight: bold;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
}

.action-link:focus {
  outline: 0;
}

.btn {
  border-radius: 5px !important;
  font-weight: bold !important
}
.btn:disabled {
  opacity: 0.4 !important;
}

.btn-primary {
  background-color: $blue-accent-color !important;
  border-color: $blue-accent-color !important;
  font-size: 1.1rem !important;
}

.btn-secondary {
  font-size: 1.1rem !important;
}

.btn-primary:hover {
  background-color: $blue-accent-color-hover !important;
  border-color: $blue-accent-color-hover !important;
}
.btn-primary:disabled:hover {
  background-color: $blue-accent-color !important;
  border-color: $blue-accent-color !important;
}

.highlight-button {
  background-color: $accent-color !important;
  border-color: $accent-color !important;
  font-size: 1.1rem !important;
}

.highlight-button:hover {
  background-color: $accent-color-highlight !important;
  border-color: $accent-color-highlight !important;
}

.capsule-button:hover {
  background-color: #e5be43;
}

.capsule-button:disabled {
  background-color: rgba(229,190,67,0.4);
}

.capsule-button:disabled:hover {
  cursor: default;
}


img.notes-icon {
  width: 18px;
  height: auto;
  opacity: 0.5;
  box-shadow: 0px 2px 2px rgba(0,0,0,0.4);
}

.slider-tooltip {
  // background-color: red;
  .rc-slider-tooltip-inner {
    background-color: #333;
    height: 32px;
    padding: 2px 12px;
    font-size: 1rem;
    line-height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .rc-slider-tooltip-arrow {
    border-top-color: #333;
    margin-left: -8px;
    border-width: 8px 8px 0;
  }
}




/* Spinner */
.spinner {
  margin: 100px auto;
  width: 60px;
  height: 60px;
  position: relative;
  text-align: center;

  -webkit-animation: sk-rotate 2.0s infinite linear;
  animation: sk-rotate 2.0s infinite linear;
}

.dot1, .dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #888;
  border-radius: 100%;

  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.spinner-secondary-nav {
  margin: 0 auto;
  width: 20px;
  height: 20px;
  .dot1, .dot2 {
    background-color: #aaa;
  }
}

.spinner-button {
  margin: 0 auto;
  width: 20px;
  height: 20px;
  .dot1, .dot2 {
    background-color: #fff;
  }
}

.spinner-page {
  margin-top: 200px;
}

.spinner-medium {
  margin: 0 auto;
  width: 40px;
  height: 40px;
  .dot1, .dot2 {
    background-color: #aaa;
  }
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-rotate { 100% { -webkit-transform: rotate(360deg) }}
@keyframes sk-rotate { 100% { transform: rotate(360deg); -webkit-transform: rotate(360deg) }}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.6, 0.6, 0.6);
  }

  40% {
    opacity: 1;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

.zoomIn {
  animation-timing-function: ease;
  animation-name: zoomIn;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}



// Alt spinner 1
.loader {
  --path: #2F3545;
  --dot: #e95656;
  --duration: 3s;
  width: 44px;
  height: 44px;
  position: relative;
  &:before {
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50%;
      position: absolute;
      display: block;
      background: var(--dot);
      top: 37px;
      left: 19px;
      transform: translate(18px, -18px);
      animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  }
  svg {
      display: block;
      width: 100%;
      height: 100%;
      circle {
        fill: none;
        stroke: var(--path);
        stroke-width: 16px;
        stroke-linejoin: round;
        stroke-linecap: round;
      }
      circle {
        stroke-dasharray: 150 50 150 50;
        stroke-dashoffset: 175;
        animation: pathCircle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
      }
  }
}


@keyframes dotRect {
  25% {
    transform: translate(0, 0);
    // transform: translate(0, -36px);
      // transform: translate(0, 0);
  }
  50% {
    transform: translate(-18px, -18px);
    // transform: translate(0, -36px);
      // transform: translate(18px, -18px);
  }
  75% {
      transform: translate(0, -36px);
  }
  100% {
    transform: translate(18px, -18px);
  }
}

@keyframes pathCircle {
  25% {
    stroke-dashoffset: 125;
  }
  50% {
    stroke-dashoffset: 75;
  }
  75% {
    stroke-dashoffset: 25;

  }
  100% {
    stroke-dashoffset: -25;
  }
}

.loader {
  display: inline-block;
  margin: 0 16px;
}






// Popover

.popover {
  width: auto;
  max-width: 100%;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.2);
}

.popover-sm {
  width: 200px;
}

.popover-med {
  width: 300px;
}

.popover-header {
  font-weight: bold;
  font-size: 1.3rem;
}

.popover-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  button {
    flex-grow: 1;
    flex-shrink: 1,;
  }
  button:last-child {
    margin-left: 5px;
  }
}

a.popover-item {
  display: flex;
  color: #111;
  border-bottom: 1px solid #eee;
  padding: 4px 8px;
}

a.popover-item:hover {
  text-decoration: none;
  background-color: #eee;
}

a.popover-item-selected, a.popover-item-selected:hover {
  background-color: $light-dark-color;
  color: #fff;
  border-radius: 5px;
  .secondary-text {
    color: #fff;
  }
}

// Dropdown
.dropdown-contained {
  background-color: #fff;
  color: #888;
  border: 1px solid #ddd;
  padding: 4px 12px;
  border-radius: 6px;
  margin-right: 20px;
  margin-left: 5px;
  font-weight: normal !important;
  cursor: pointer;
}
.dropdown-inline::after {
  color: #ddd;
}

// Segment control
.selected-item {
  text-align: center;
}

.segment-control-container {
  --highlight-width: auto;
  --highlight-x-pos: 0;

  display: flex;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 2px;

  .segment-control {
    display: inline-flex;
    justify-content: space-between;
    background: #fff;
    overflow: hidden;
    position: relative;
  }

  .segment {
    width: 100%;
    min-width: 180px;
    position: relative;
    text-align: center;
    z-index: 1;
    padding: 0;
  }

  .segment label {
    cursor: pointer;
    display: block;
    font-weight: 700;
    padding: 10px;
    margin-bottom: 0;
    margin: 0 !important;
    // transition: color 0.4s ease;
  }

  .segment.active label {
    color: #fff;
  }

  .segment-control-underlay {
    background: #5465ff;
    background: $accent-color;
    border-radius: 6px;
    width: var(--highlight-width);
    transform: translateX(var(--highlight-x-pos));
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    opacity: 0;
  }

  /* Only allow transitions once component is ready */
  .segment-control-underlay-ready {
    // transition: opacity 0.2s ease, transform 0.2s ease, width 0.2s ease;
    opacity: 1;
  }
  .dropdown-item:disabled {
    color: #ccc;
  }
}



// Matchup
.matchup-card {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .actions {
    color: #dbdde4;
    margin-right: 20px;
    text-align: center;
    align-items: center;
    display: flex;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 12px;
      margin-left: 5px;
      background-color: #f3f3f3;
      color: #888;
      border-radius: 20px;
      white-space: nowrap;
      svg {
        margin-right: 5px;
      }
    }
    a:hover {
      text-decoration: none;
      background-color: #eee;
      color: #666;
    }
    a.active {
      background-color: #333;
      color: #fff;
    }
  }
  @media print {
    display: none;
  }
}

.matchupRowLabel {
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1.1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.matchupRowValues {
  position: relative;
  width: 100%;
}


.carbly-row {
  .graph-condition-range {
    background-color: $blue-accent-color !important;
  }
}

.wholesale-matchup-graph .carbly-row .graph-condition-range {
  background-color: #505d62 !important;
  border: 1px solid #505d62 !important;
}
.retail-matchup-graph .carbly-row .graph-condition-range {
  background-color: $blue-accent-color !important;
  border: 1px solid $blue-accent-color !important;
}

.graph-container {
  margin-right: 20px;
  padding-bottom: 40px;
  .scale-grid {
    background-color: #eee;
  }
  .cursor, .cursor-price {
    position: absolute;
    background-color: $medium-dark-color;
    color: #fff;
  }
  .cursor-spread-box {
    position: absolute;
    border-top: 2px solid #eee;
    border-left: 2px solid #eee;
    border-right: 2px solid #eee;
  }
  .cursor-spread-box-price {
    position: absolute;
    color: #bbb;
  }
  .marker, .marker-price {
    position: absolute;
    background-color: $medium-dark-color;
    color: #fff;
    transition: left 0.3s ease;
  }
  .cursor-price, .marker-price {
    text-align: center;
    border-radius: 4px;
  }
  .marker-price:after, .cursor-price:after {
  	content: '';
  	position: absolute;
  	bottom: 0;
  	left: 50%;
  	width: 0;
  	height: 0;
  	border: 7px solid transparent;
  	border-top-color: $medium-dark-color;
  	border-bottom: 0;
  	margin-left: -6px;
  	margin-bottom: -7px;
  }
  .graph-condition {
    position: absolute;
    background-color: $light-color;
  }
  .graph-condition-start {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .graph-condition-end {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .graph-condition-single {
    background-color: $medium-dark-color !important;
    border-radius: 12px;
  }
  .condition-marker-label {
    position: absolute;
    text-align: center;
    font-size: 0.88rem;
    line-height: 0.88rem;
    color: $light-color;
    background-color: #fff;
  }
  .graph-marker-condition {
    position: absolute;
    text-align: center;
    font-size: 0.85rem;
    line-height: 0.85rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: $medium-dark-color;
    padding: 10px 4px;
    border-radius: 4px;
    transition: opacity 0.3s ease;
  }
  .graph-marker-condition:after {
  	content: '';
  	position: absolute;
  	top: 0;
  	left: 50%;
  	width: 0;
  	height: 0;
  	border: 6px solid transparent;
  	border-bottom-color: $medium-dark-color;
  	border-top: 0;
  	margin-left: -6px;
  	margin-top: -6px;
  }
  .graph-condition-range {
    position: absolute;
    background-color: #eee;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  .graph-x-axis-grid {
    position: absolute;
    height: 1px;
    left: 0;
    width: 100%;
    background-color: #eee;
  }
}


// Bookouts
.bookout-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  h2 {
    flex-grow: 1;
    font-size: 2rem;
    color: #111;
    @media screen and (max-width: $break-extension) {
      font-size: 1.6rem;
    }
  }
}

.bookout-providers-c {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px 10px;
  @media screen and (max-width: $break-extension) {
    display: block;
    a {
      display: block;
      margin: 0 auto 10px auto;
      padding: 0;
    }
  }
}

.bookout-body {
  display: flex;
  flex-direction: row;
}

.bookout-provider-select-button {
  width: 230px;
  text-align: center;
  opacity: 0.6;
  h3 {
    font-size: 1.3rem;
    font-weight: bold;
  }
}
.bookout-provider-select-button:hover {
  opacity: 1;
}

.bookout-form {
  width: 100%;
  .form-field-c {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 30%;
    padding: 0;
    margin: 0;
    @media screen and (max-width: $break-extension) {
      width: 45%;
    }
  }
  label {
    text-transform: none;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.2rem;
  }
}

.bookout-condition-c {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: $break-extension) {
    display: block;
  }
  .bookout-condition-check-c {
    width: 300px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 20px;
    > div {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      align-items: center;
      padding: 0;
      margin: 0;
      @media screen and (max-width: $break-extension) {
        justify-content: flex-start;
        margin-top: 10px;
      }
    }
    @media screen and (max-width: $break-extension) {
      margin-left: 0;
    }
  }
  input {
    background-color: red;
    flex-grow: 0;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;
    margin-bottom: 8px;
    margin-right: 5px;
  }
}

.bookout-download-button {
  color: green;
  display: inline-block;
  background-color: $accent-color;
  color: #fff;
  font-weight: bold;
  padding: 10px 16px;
  border-radius: 6px;
  text-decoration: none;
}

.bookout-download-button:hover {
  color: #fff;
  text-decoration: none;
}


// Regional Reports Plus
.regional-reports-plus-body-c {
  overflow: initial; // override for sticky subnav
  margin-left: $primary-nav-width + 20px;
  padding: $standard-margin;
  padding-right: $standard-margin + 10px;
  .top-level-stats {
    margin-top: 20px;
    gap: 10px;
    > div {
      border: 1px solid #aaa;
      border-radius: 4px;
      padding: 10px;
      text-align: center;
      width: 200px;
      h3 {
        font-size: 1.4rem;
        margin-bottom: 0;
        font-weight: bold;
      }
    }
  }

  #dealer-performance {
    .report-cell {
      .list-emphasis {
        font-size: 1rem;
        font-weight: normal;
      }
    }
  }

  .regional-subnav {
    // -0.1px top hack for observer to detect when it's stuck
    // z-index must be above chart tooltips, behind modal, behind sidenav shadow/pointer
    // negative margin + offsetting padding to stretch beyond page content padding
    position: sticky;
    top: -0.1px;
    z-index: 995;
    padding: 10px 30px 10px 40px;
    background-color: $body-bg-color;
    margin: 10px -30px 0 -40px;
    &.stuck {
      box-shadow: 0 5px 22px rgba(0,0,0,0.09);
      border-bottom: 1px solid #aaa;
    }
    a {
      background-color: #eee;
      color: #888;
      border-radius: 30px;
      padding: 2px 10px;
    }
    a.active {
      background-color: #111;
      color: #fff;
    }
    a:hover {
      text-decoration: none;
      background-color: #ddd;
    }
    a.active:hover {
      background-color: #111;
    }
  }
  section {
    margin: 10px 0 40px 0;
    padding-top: 10px;
    h1 {
      font-size: 2rem;
      font-weight: bold;
      color: #111;
      padding-bottom: 0 !important;
      margin-bottom: 0;
      border-bottom: 4px solid #111;
    }
    h2 {
      margin-top: 0;
    }
  }
  .report-list-row {
    border-bottom: 1px solid #ddd;
  }
  .report-list-row:nth-child(odd) {
    background-color: #f2f2f2;
  }
  .report-list-row:hover {
    background-color: #ebebeb;
    .retail-model-action-row {
      a {
        background-color: $accent-color;
        border: 1px solid $accent-color;
        color: #fff;
      }
    }
  }
  .report-cell {
    border-radius: 10px;
    border: 1px solid #aaa;
    padding: 10px;
    box-shadow: 0 5px 22px rgba(0,0,0,0.09);

    .list-order-number {
      color: #888;
      width: 32px;
      font-size: 1rem;
      flex-shrink: 0;
    }
    .list-emphasis, a {
      flex-grow: 1;
      flex-shrink: 1;
      // font-size: 1.1rem;
      font-weight: 500;
    }
    .list-title {
      flex: 1;
      min-width: 0;
      margin-right: 4px;
      .list-emphasis {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 1.25rem;
        letter-spacing: -0.5px;
      }
    }
    .list-value {
      flex-grow: 0;
      flex-shrink: 0;
      margin-left: 10px;
    }
    .secondary-text {
      margin-top: -3px;
    }
    .pagination {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 5px;
      margin-top: 5px;
      a {
        display: block;
        background-color: #2c6e8e;
        color: #fff;
        text-align: center;
        border-radius: 4px;
        svg {
          color: #fff;
          margin-top: -2px;
        }
      }
      a:hover {
        text-decoration: none;
        background-color: #23546d;
      }
    }
  }
  a.dealers-filter {
    border-radius: 20px;
    padding: 4px 12px;
    background-color: #ddd;
    color: #111;
  }
  a.dealers-filter:hover {
    background-color: #ccc;
    text-decoration: none;
  }
  a.dealers-filter.active {
    background-color: $accent-color;
    color: #fff;
    font-weight: bold;
  }
  a.dealers-filter.active:hover {
    cursor: default;
  }
}

.retail-model-action-row {
  display: flex;
  gap: 6px;
  margin-bottom: 4px;
  > * {
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
  }
  a {
    padding: 2px 12px;
    border-radius: 24px;
    border: 1px solid #ddd;
    margin-bottom: 2px;
    color: #666;
    font-weight: 500;
  }
  a:hover {
    text-decoration: none;
    background-color: $accent-color-highlight !important;
    border: 1px solid $accent-color-highlight !important;
    color: #fff;
  }
}

.retail-model-stats-summary-item {
  background-color: #fff;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 18px 8px;
  h2 {
    font-size: 2rem;
  }
}


// My Lot
.my-lot-body-c {
  padding: $standard-margin;
}

.my-lot-dashboard-header {
  background-image: url('../assets/images/my-lot-header-bg.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-color:rgba(0, 0, 0, 0.5);
  margin: -$standard-margin;
  margin-left: -$standard-margin * 2;
  margin-bottom: 10px;
  min-height: 120px;
  padding: $standard-margin;
  padding-left: $standard-margin * 2;
  align-items: flex-end;
  color: #fff;
  .content-header {
    align-items: flex-start;
    h1 {
      color: #fff;
      line-height: 2.2rem;
    }
    .global-tools {
      margin-top: 2px;
    }
  }
}

.my-lot-snapshot-c {
  display: flex;
  column-gap: 10px;
  margin-top: 10px;
  align-items: center;
  > div {
    background-color: rgba($color: #fff, $alpha: 0.8);
    border-radius: 6px;
    padding: 8px;
    color: #111;
    width: 200px;
    .snapshot-value {
      font-size: 1.5rem;
      font-weight: bold;
      margin-top: -7px;
    }
    .snapshot-label {
      opacity: 0.5;
      margin-top: -7px;
      font-size: 0.9rem;
    }
  }
}

h3.my-lot-chart-title {
  font-size: 1.2rem;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.my-lot-vehicles-c {
  .card-list-row {
    margin-right: 0 !important;
  }
}

.lot-vehicle-vdp {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid #ddd;
  h2 {
    margin-top: 0;
  }
  .section-header {
    border-bottom: 1px solid #ddd;
    margin-top: 40px;
    margin-bottom: 20px;
    h3 {
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
  .headlines {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    width: 100%;
    .transactions-c {
      height: 100%;
      flex-grow: 1;
      flex-shrink: 1;
      display: flex;
      flex-direction: column;
      .split-row {
        min-height: 94px;
        column-gap: 10px;
        flex-grow: 1;
        flex-shrink: 1;
        .headline {
          height: 100%;
          min-height: 94px;
          background-color: #e8e8e8;
          justify-content: flex-start;
          min-width: 168px;
        }
      }
      .split-row:first-child {
        margin-bottom: 10px;
      }
      button {
        border-radius: 6px;
        background-color: $accent-color;
        background-color: #555;
        color: #fff;
        font-weight: bold;
        font-size: 1.1rem;
        border: 1px solid $accent-color;
        border: 1px solid #555;
        margin-top: 10px;
        width: 100%;
        padding: 8px 5px;
        &:hover {
          background-color: #333;
        }
        &:active, &:focus {
          outline: 0;
        }
      }
    }
    .stats-c {
      display: flex;
      flex-direction: row;
      column-gap: 10px;
      flex-grow: 1;
      flex-shrink: 1;
      width: 100%;
    }
    .headline {
      flex-shrink: 1;
      flex-grow: 1;
      flex-basis: 0;
      border-radius: 6px;
      text-align: center;
      padding: 10px;
      background-color: #e8e8e8;
      border-radius: 6px;
      text-align: center;
      padding: 10px;
      display: flex;
      flex-direction: column;
      .headline-feature {
        font-weight: bold;
        font-size: 2rem;
        margin-bottom: -3px;
        margin-top: -8px;
      }
      .headline-title {
        font-size: 0.9rem;
        font-weight: bold;
        color: #aaa;
        text-transform: uppercase;
      }
      .headline-secondary {
        color: #888;
        font-size: 0.9rem;
        line-height: 1rem;
      }
    }
    .headline-body {
      flex-grow: 1;
      flex-shrink: 1;
    }
    .headline-footer {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
  .thumbnails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 5px;
    > div {
      width: 70px;
      border-radius: 4px;
      overflow: hidden;
    }
  }

}

.lot-vehicle-state-button {
  background-color: transparent;
  border: 1px solid $accent-color !important;
  border-radius: 4px;
  line-height: 1rem !important;
  padding: 2px 4px !important;
  margin: 0;
  color: $accent-color;
  outline: none !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
}

.lot-vehicle-state-button-large {
  font-size: 1.2rem;
  line-height: 1.2rem;
  padding: 4px 8px !important;
}

.lot-vehicle-state-button:hover {
  background-color: $accent-color !important;
  color: #fff !important;
  cursor: pointer;
}

.my-lot-select-state-icon {
  color: #333;
  width: 30px;
  svg {
    width: 20px;
    height: 20px;

  }
}

.my-lot-vehicle-row {
  margin: 0 !important;
  a {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    padding: 10px 20px;
  }

  .thumbnail {
    width: 140px !important;
    height: 105px !important;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 10px;
    position: relative;
    overflow: hidden;
    img {
      width: 140px !important;
      height: auto !important;
      border-radius: 4px;
    }
  }

  .my-lot-vehicle-body {
    flex: 1;
    > .row {
      flex-grow: 1;
      flex-shrink: 1;
      display: flex;
      flex-wrap: nowrap;
      margin: 0;
      gap: 10px;
    }

    .year-make {
      font-size: 1.1rem;
      font-weight: bold;
      color: #aaa;
    }
    .model {
      margin-top: -3px
    }

    .trim {
      margin-top: -5px
    }

    .listing-basics {
      flex-grow: 1;
      flex-shrink: 1;
      min-width: 236px;
    }

    .listing-dol {
      min-width: 140px;
    }

    .listing-price-changes {
      min-width: 180px;
    }

    .profit {
      min-width: 160px;
      .profit-amount {
        font-weight: bold;
        font-size: 1.1rem;
      }
    }

    .comps {
      min-width: 160px;
      button {
        color: #888;
        border: 1px solid #888 !important;
        border-radius: 4px;
        line-height: 1rem !important;
        padding: 2px 4px !important;
        outline: none;
        background-color: transparent;
      }
      button:hover {
        color: #fff;
        border: 1px solid $accent-color !important;
        background-color: $accent-color !important;
      }
    }

    .no-comps {
      button {
        opacity: 0.5;
      }
      button:hover {
        color: #888;
        border: 1px solid #888 !important;
        background-color: transparent !important;
      }
    }

    .pricing {
      text-align: right;
      width: 140px;
      .list-price {
        font-size: 1.65rem;
        font-weight: bold;
        margin-bottom: -8px;
      }

      .list-acv {


        margin-top: 5px;
      }
    }

    .edit {
      width: 1.5em;
      margin-left: 5px;
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      button {
        border: none;
        outline: none;
        background-color: transparent;
        opacity: 0.3;
      }
      button:hover {
        opacity: 1;
      }
    }

    .capsules {
      margin-top: 5px;
      align-items: center;
    }
  }

}


// Retail comps
.listings-list-price {
  flex-grow: 0;
  flex-shrink: 0;
  width: 80px;
  min-width: 80px;
  font-weight: bold;
}

.listings-list-mileage {
  flex-grow: 0;
  flex-shrink: 0;
  width: 110px;
}

.listings-list-distance {
  flex-grow: 0;
  flex-shrink: 0;
  width: 92px;
}

.listings-list-dol {
  flex-grow: 0;
  flex-shrink: 0;
  width: 74px;
}

.listings-list-price-changes {
  flex-grow: 0;
  flex-shrink: 0;
  width: 84px;
}

button.text-button {
  background-color: transparent;
  border: none;
  padding: 0;
  color: $accent-color;
  text-align: left;
  &:active, &:focus {
    outline: 0;
  }
}

svg.my-lot-nav-icon {
  width: 20px !important;
  height: 20px !important;
  margin-right: 10px !important;
  color: $light-color;
}

.my-lot-transaction-form {
  form {
    padding: 20px;
  }
  section {
    margin-bottom: 30px;
  }
  h2 {
    margin-top: 0;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0;
    border-bottom: 2px solid #aaa;
    margin-bottom: 8px;
  }
  .input-label {
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #555;
  }
  .date-picker {
    input {
      border: 1px solid #ced4da;
      border-radius: 4px;
      padding: 6px;
      margin-top: 2px;
    }
  }
}

.my-lot-vehicle-state-control-icon {
  width: 22px;
  height: 22px;
  margin-left: 10px;
}

.my-lot-alert-previews-c {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 60px;
}

.my-lot-alert-vehicle-preview {
  > a {
    height: 100%;
    color: $default-text-color;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 4px;
    width: 330px;
    gap: 10px;
    box-shadow: 0 5px 12px rgba(0,0,0,0.09);
    .thumbnail {
      width: 90px !important;
      height: 68px !important;
      flex-shrink: 0;
      flex-grow: 0;
      position: relative;
      overflow: hidden;
      img {
        width: 90px !important;
        height: auto !important;
        border-radius: 4px;
      }
    }

    .info {
      overflow: hidden;
      flex-grow: 1;
      .year {
        color: #888;
        font-size: 0.9rem;
        font-weight: bold;
        line-height: 0.9rem;
      }
      .make-model {
        margin-bottom: 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .alerts {
        display: flex;
        flex-direction: column;
        gap: 4px;
        > div {
          display: flex;
        }
      }
    }
    .snooze {
      padding: 0 4px;
    }
  }
}

.my-lot-regional-metrics {
  width: 270px;
  flex-grow: 0;
  flex-shrink: 0;

  h2 {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 0px;
    letter-spacing: -0.4px;
    margin-top: -2px;
  }
  h2.subsection-title {
    font-size: 1.1rem;
    color: #666;
  }
  section {
    border: 1px solid #6fa0ac;
    border-radius: 6px;
    padding: 8px;
    margin-bottom: 5px;
    background-color: rgb(193,192,189);
    background-color: #6fa0ac;
    color: #c5dfe7;
    font-size: 0.9rem;
    .section-label {
      margin-top: -4px;
      font-size: 0.7rem;
      font-weight: bold;
      color: #c5dfe7;
      text-transform: uppercase;
    }
    h2 {
      font-size: 1.2rem;
      color: #fff;
    }
    .indicator {
      color: #fff;
      font-size: 1rem;
    }
  }
  section:last-child {
    margin-bottom: 0;
  }
}

.my-lot-regional-comps {
  flex-grow: 1;
  flex-shrink: 1;
  border-radius: 1px solid #ddd;
  border-radius: 6px;
  position: relative;
  > div {
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    position: absolute;
  }

  @media screen and (max-width: 1700px) {
    .listings-list-photo {
      width: 70px !important;
      height: 70px !important;
    }
  }

  @media screen and (max-width: 1600px) {
    .listings-list-photo {
      width: 50px !important;
      height: 50px !important;
    }
  }
}


.my-lot-scorecard-compact {
  @media screen and (max-width: 1500px) {
    .my-lot-regional-metrics {
      width: 230px;
    }

  }

}

.my-lot-alert-capsule {
  border: 1px solid $accent-color !important;
  border-radius: 4px;
  line-height: 1rem !important;
  padding: 2px 4px !important;
  outline: none !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-color: #ddd !important;
  color: #444 !important;
  background-color: #eee;
  svg {
    margin-right: 5px;
    color: #888 !important;
  }
}

.lot-history-modal-metrics {
  display: flex;
  column-gap: 10px;
  div {
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 4px 10px;
    background-color: #f2f2f2;
    font-weight: bold;
  }
}

.strategy-widget-section {
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 10px;
}

.strategy-widget-controls-c {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  @media screen and (max-width: $break-extension) {
    display: block;
  }
}

.strategy-widget-alert {
  border-radius: 4px;
  line-height: 1rem !important;
  padding: 2px 4px !important;
  outline: none !important;
  display: inline;
  flex-direction: row;
  align-items: center;
  color: #444 !important;
  svg {
    margin-bottom: 4px;
    margin-left: -6px;
  }
}

.strategy-widget-preset-button {
  text-align: left;
  border: 1px solid $accent-color !important;
  margin-bottom: 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.strategy-widget-preset-button:hover {
  background-color: $accent-color !important;
  color: #fff !important;
  text-decoration: none;
}

.strategy-widget-preset-button:disabled:hover {
  background-color: #fff !important;
  border: 1px solid $accent-color !important;
  color: $accent-color !important;
}

.strategy-results-c {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 20px;
  row-gap: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
  > div {
    background-color: #d8ebf3;
    border-radius: 6px;
    border: 2px solid #d8ebf3;
    display: flex;
    align-items: center;
    .label, .value {
      padding: 4px 8px;
    }
    .value {
      background-color: #fff;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      margin-right: 1px;
      font-weight: bold;
      text-align: center;
    }
  }
  
  @media screen and (max-width: $break-extension) {
    flex-wrap: wrap;
    div {
      width: 45%;
    }
  }
}

.strategy-best-results-c {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 8px;
  @media screen and (max-width: $break-extension) {
    flex-wrap: wrap;
    div {
      width: 45%;
    }
  }
}

.strategy-results-charts-c {
  display: flex;
  flex-direction: row;
  flex: 1;
  @media screen and (max-width: $break-extension) {
    display: block;
    .strategy-widget-section {width: 100% !important}
  }
  .strategy-results-chart-title {
    text-align: center;
  }
}

.my-lot-alert-capsule-large {
  font-size: 1.2rem;
  line-height: 1.2rem;
  padding: 4px 8px !important;
}

.strategy-widget-valuation {
  .strategy-widget-list-price-c {
    width: 65% !important;
    @media screen and (max-width: $break-extension) {
      width: 100% !important;
    }
  }
  .strategy-widget-price-input-c {
    width: 140px !important;
  }
  .strategy-widget-days-input-c {
    width: 60px !important;
  }
  .strategy-widget-turn-c {
    @media screen and (max-width: $break-extension) {
      width: 100% !important;
      > div > div {
        padding-left: 0 !important;
      }
    }
  }
}

.strategy-widget-presets-c {
  width: 140px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 10px;
  @media screen and (max-width: $break-extension) {
    flex-direction: row;
    column-gap: 10px;
    margin-left: 0;
    width: 100%;
    button {
      width: 50%;
    }
  }
}

.listings-sort-header {
  a {
    color: #111;
  }
}

.my-lot-search-modal-c {
  height: 680px;
  .card {
    border-radius: 0;
  }
}

.my-lot-onboarding-widgets {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
  > div {
    max-width: 400px;
    margin-right: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: #f2f2f2;
    .widget-contents-c {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .onboarding-widget-body {
      flex-grow: 1;
      flex-shrink: 1;
      display: flex;
      .icon {
        margin-right: 10px;
        svg {
          width: 28px;
          height: 28px;
        }
      }
    }
    .onboarding-widget-footer {
      flex-grow: 0;
      flex-shrink: 0;
    }
    h3 {
      font-size: 1.1rem;
      font-weight: bold;
      margin-bottom: 5px;
    }
    p {
      font-size: 0.9rem;
      line-height: 1.1rem;
    }
  }
  > div.emphasized {
    border: 1px solid $blue-accent-color;
    box-shadow: 0 4px 16px rgba(0,0,0,0.1);
  }
}

.my-lot-onboarding-widgets-wide {
  > div {
    max-width: 100%;
    width: 100%;
    flex-basis: 100%;
    .onboarding-widget-footer .split-row {
      justify-content: flex-end;
      column-gap: 30px;
    }
  }
}

.my-lot-onboarding-widgets-home {
  > div {
    background-color: #fff;
    border-radius: 10px;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 4px 16px rgba(0,0,0,0.1);
  }
}

.my-lot-full-onboarding-widgets-c {
  .my-lot-onboarding-widgets > div {
    max-width: 33%;
    width: 33%;
    flex-basis: 33%;
  }
  .onboarding-widget-body {
    .icon {
      margin-right: 10px;
      svg {
        width: 40px !important;
        height: 40px !important;
      }
    }
  }
  .onboarding-widget-footer {
    font-weight: bold !important;
    font-size: 1.1rem ;
    > div {
      display: block;
      text-align: center;
    }
    button {
      background-color: $accent-color;
      color: #fff;
      font-weight: bold;
      padding: 8px 16px;
      margin-top: 10px;
      border-radius: 10px;
    }
  }
}


.my-lot-settings-c {
  display: flex;
  flex-direction: column;
  gap: 30px;
  > div {
    border: 1px solid #aaa;
    padding: 20px;
    border-radius: 6px;
  }
  h2 {
    margin-top: 0;
    line-height: 1.2rem;
    margin-bottom: 10px;
  }
}

a.my-lot-analysis-snapshot-section {
  text-decoration: none;
  color: inherit;
}

a.my-lot-analysis-snapshot-section:hover > div{
  background-color: #ddd !important;
  box-shadow: 0 2px 14px rgba(0,0,0,0.2);
}

.my-lot-competitors-scope-c {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  margin-top: 10px;
  padding-bottom: 10px;
  a {
    background-color: #eee;
    border-radius: 6px;
    padding: 5px 10px;
    color: #555;
  }
  a.selected, a:hover {
    background-color: #666;
    color: #fff;
  }
  a:hover {
    text-decoration: none;
  }
}

.my-lot-competitors-ranks-c {
  display: flex;
  margin: 20px 0;
  justify-content: space-around;
  .my-lot-competitors-rank {
    width: 120px;
    height: 120px;
    background-color: darkorange;
    border: 4px solid darkorange;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 5px 10px rgba(0,0,0,0.12);
  }
  .my-lot-competitors-rank-value {
    font-weight: bold;
    color: #fff;
    text-align: center;
    line-height: 2.2rem;
  }
  .my-lot-competitors-rank-label {
    color: rgba(255,255,255,0.8);
    text-align: center;
    margin-top: -16px;
  }
  .my-lot-competitors-rank-type-label {
    font-weight: bold;
    text-align: center;
  }
  .my-lot-competitors-rank-type-value {
    text-align: center;
    font-size: 0.95rem;
    margin-top: -2px;
    color: #666;
  }
}

.my-lot-competitors-list {
  height: 100%;
  max-height: 500px;
  border: 1px solid #bbb;
  width: 100%;
  overflow-y: scroll;
  .my-lot-competitors-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    .rank {
      flex-shrink: 0;
      font-weight: bold;
      width: 44px;
      height: 44px;
      border-radius: 22px;
      background-color: #888;
      color: #fff;
      text-align: center;
      line-height: 44px;
      margin-right: 10px;
    }
    .dealer-details {
      flex-grow: 1;
      flex-shrink: 1;
      .dealer-name {
        font-weight: bold;
        font-size: 1.1rem;
      }
      .dealer-value {
        margin-top: -5px;
        color: #888;
      }
    }

  }
  .my-lot-competitors-list-item-selected {
    background-color: darkorange;
    .rank {
      background-color: #fff;
      color: darkorange;
      box-shadow: 0 5px 10px rgba(0,0,0,0.12);
    }
    .dealer-details {
      .dealer-name {
        color: #fff;
      }
      .dealer-value {
        color: rgba($color: #fff, $alpha: 0.8);
      }
    }
  }
}


.react-datepicker-wrapper {
  input, textarea, .date-picker-input {
    margin-bottom: 0 !important;
  }
}



// Toast
.toast-c {
  border-radius: 8px !important;
  margin-top: 10px;
  margin-right: 10px;
}

.toast-progress {
  background-color: rgba(255,255,255,0.6) !important;
}

.Toastify__toast {
  &--default {
    background: $alert-success-color !important;
  }

  &--success {
    background: $alert-success-color !important;
  }

  &--error {
    background: $alert-error-color !important;
  }

  &--info {
    background: $alert-default-color !important;
  }

  &--warning {
    background: $alert-warning-color !important;
  }
}


// Extension overrides
.extension-upsell-button {
  svg {
    width: 22px;
    height: 22px;
    margin-right: 5px;
  }
}
.extension-page-c {
  .valuation-hud {
    left: 0
  }
  a.vehicle-found-external {
    margin-top: 0;
  }
  .body-content {
    padding-left: 20px;
    padding: 10px;
  }
  .new-valuation-form .browse-link {
    display: none;
  }
  #vin-instructions {
    display: none;
  }
  .extension-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    img {
      height: 32px;
    }
  }
  .valuation-section {
    margin-bottom: 20px;
  }

  .body-content {
    padding-top: 0;
  }
  .content-context-actions {
    margin-bottom: $standard-margin;
  }
  .data-table {
    font-size: 0.88rem;
  }
  .extension-save-button {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $blue-accent-color;
    padding: 5px;
    font-weight: bold;
    font-size: 1.25rem;
    text-align: center;
    color: #fff !important;
  }
  .extension-save-button:hover {
    background-color: $blue-accent-color-hover;
    text-decoration: none;
  }
}

.extension-no-access-body-c {
  padding: $standard-margin;
  text-align: center;
  .content-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    border-bottom: 1px solid #aaa;
    padding-bottom: 10px;
    margin-bottom: 10px;
    text-align: left;

    h1 {
      margin: 0;
    }
    svg {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      color: #f26522;
    }
  }
  .add-extension {
    margin-bottom: 40px;
    svg {
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }
  }
}

// Extension promo
.extension-body-c {
  margin-left: $primary-nav-width + 20px;
  padding: $standard-margin;
  padding-right: $standard-margin + 10px;
  .extension-icon svg {
    width: 90px;
    height: 60px;
  }
  h2 {
    margin-top: 40px;
  }
  .extension-instruction {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    h3 {
      margin: 0;
      font-weight: bold;
      font-size: 1.2rem;
    }
    .number {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background-color: orange;
      line-height: 40px;
      text-align: center;
      font-size: 1.3rem;
      font-weight: 700;
      color: #fff;
      flex-grow: 0;
      flex-shrink: 0;
    }
    .number-instructions {
      margin-left: 20px;
    }
    img.extension-tab-screenshot {
      width: 50px;
      margin-left: 20px;
      height: auto;
      float: right;
    }
    a.install-button {
      display: block;
      border: 1px solid #666;
      border-radius: 8px;
      padding: 10px;
      background-color: #fff;
      color: #111;
      margin-bottom: 20px;
      > div {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }
    }
    a.install-button:hover {
      text-decoration: none;
    }
  }
}



// iFrame overrides > extension overrides
.iframe-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  img {
    width: 80px;
    height: 26px;
  }
}
.iframe-page-c {
  section.valuation-section {
    padding: 10px;
  }
  .body-content {
    padding-top: 0;
    padding-left: 10px;
    padding-right: 10px;
  }
  .content-header h1 {
    font-size: 1.75rem;
  }
  .carfax-preview-data {
    display: block;
    .carfax-preview-item {
      width: 100%;
      margin-right: 0;
      margin-bottom: 2px;
    }
  }
  .new-valuation-form .browse-link {
    display: none;
  }
  #vin-instructions {
    display: none;
  }
  .content-context-actions {
    margin-bottom: $standard-margin;
  }
  .data-table {
    font-size: 0.88rem;
  }
  .kbb-table {
    .prices-table-cell-6, .row-label-6 {
      width: 20% !important;
    }
    .spread-cell, .prices-table-cell-separator {
      display: none;
    }
  }
  .adjustments-toggle-c {
    display: block !important;
  }
  a.adjustments-toggle {
    display: block;
    font-size: 0.85rem;
    text-align: right !important;
  }
  .provider-footer-info {
    font-size: 0.9rem;
  }
}

.iframe-no-access-body-c {
  margin-left: 0;
  padding: $standard-margin;
}

.partner-preview-access-message {
  background-color: #fff2b9;
  background-image: url('../assets/images/carbly-c-white.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 31px 31px;
  border-radius: 6px;
  padding: 8px 12px 8px 50px;
  min-height: 40px;
  font-size: 1.06rem;
  line-height: 1.2rem;
  color: #382f10;
  a {
    color: #000;
    text-decoration: underline;
  }
}
