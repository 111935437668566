.app-news {
  h1 {
    font-size: 2.5rem !important;
    color: $medium-dark-color;
    margin-bottom: 40px;
    margin-top: 5px;
    font-weight: bold;
  }
  h3 {
    font-size: 1.1rem;
    color: $light-dark-color;
    font-weight: bold;
  }
  .item {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #ddd;
  }
  .item-date {
    color: $light-color;
    margin-bottom: 10px;
    font-weight: bold;
  }
}