.user-avatar {
  border-radius: 50%;
  display: flex;
  justify-content: center !important;
  align-items: center;
  overflow: hidden;

  &__initials {
    text-align: center;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    margin-left: 1px;
    margin-top: -1px;
  }

  &__image {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
  }
}
