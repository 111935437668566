$accent-color: #e95656;
$accent-color-highlight: #c73e3e;
$green-accent-color: #30aa44;
$blue-accent-color: #02b1fc;
$blue-accent-color-hover: #0099da;
$nav-text-color: #b5bcc6;
$extra-dark-color: #16181a;
$medium-dark-color: #202326;
$light-dark-color: #2f323e;
$light-color: #888;
$extra-light-color: #bbb;
$orange-tint-color: #ea8e22;
$highlight-color: #f9fadd;
$warning-yellow-color: #e3b000;
$error-color: #b00c0c;
$success-color: #128715;
$body-bg-color: #f6f7fa;
$default-text-color: $extra-dark-color;

$alert-success-color: #128715;
$alert-error-color: $accent-color-highlight;
$alert-default-color: $blue-accent-color;
$alert-warning-color: $warning-yellow-color;

$border-radius: 8px;
$section-border-radius: 12px;

$standard-margin: 20px;

$primary-nav-width: 90px;
$secondary-nav-width: 280px;
$tertiary-nav-width: 280px;

$break-small: 460px;
$break-extension: 550px;
$break-medium: 1400px;
$break-large: 1600px;
$valuation-break: 1300px;
$valuation-break-small: 900px;
