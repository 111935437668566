.edit-user-profile {
  $form-spacing: 0.5rem;

  &__photo-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
  }

  &__upload-photo {
    position: relative;
    border-radius: 50%;
    overflow: auto;
    &:focus {
      outline: none;
    }
  }

  &__overlay {
    background: rgba($extra-dark-color, 0.55);
    position: absolute;
    height: 25%;
    bottom: 0;
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.35rem;
  }

  &__field {
    margin-bottom: $form-spacing;
  }

  &__remove {
    position: absolute;
    bottom: -1.5em;
    font-size: 0.8em;
  }

  &__messages {
    margin-top: 30px;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
