@mixin flex-row($spacing: 5px) {
  display: flex;
  flex-direction: row;
  margin-left: -$spacing;
  margin-right: -$spacing;

  > * {
    padding-left: $spacing;
    padding-right: $spacing;
  }
}
