$mobile-break: 940px;

.partner-lander-c {
  
}

.partner-header-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  background: url('../../assets/images/login-bg.jpg') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: -10;
  @media screen and (max-width: $mobile-break) {
    display: none;
  }
}


.partner-lander-c .partner-lander {
  display: flex;
  flex-direction: row;
  max-width: 1400px;
  margin: 0 auto;
  padding: 40px 0;

  .partner-body li {
    margin-bottom: 5px;
  }

  @media screen and (max-width: $mobile-break) {
    flex-direction: column-reverse;
    padding: 0;
  }

  .partner-lander-valuation {
    width: 700px;
    flex-grow: 0;
    flex-shrink: 0;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 12px rgba(0,0,0,0.1);
    @media screen and (max-width: $mobile-break) {
      border-radius: 0;
    }

    @media screen and (max-width: 1130px) {
      width: 550px;
    }

    @media screen and (max-width: $mobile-break) {
      width: 100%;
    }

    .valuation-body {
      flex-direction: column-reverse;
    }
    .valuation-header {
      flex-wrap: wrap;
      div {
        margin-right: 5px;
      }
      
    }
    .body-content {
      padding: 20px;
      @media screen and (max-width: $mobile-break) {
        padding: 5px;
        .card {
          padding: 10px;
        }
      }
    }
    .valuation-sidebar {
      width: 100%;
    }
    .summary-data {
      display: flex;
      flex-direction: row;
    }

    .star-vin-message {
      padding: 20px;
    }

    @media screen and (max-width: 1130px) {
      .kbb-table {
        .prices-table-cell-6, .row-label-6 {
          width: 20% !important;
        }
        .spread-cell, .prices-table-cell-separator {
          display: none;
        }
      }
      .adjustments-toggle-c {
        display: block !important;
      }
      a.adjustments-toggle {
        display: block;
        font-size: 0.85rem;
        text-align: right !important;
      }
      .provider-footer-info {
        font-size: 0.9rem;
      }
    }

    .universe-trims-c {
      display: block;
      .universe-trim-table {
          width: 100% !important;
          margin: 0 !important;
          margin-top: 5px !important;
      }
    }
    
    .data-table {
      font-size: 0.88rem;
    }
    
    .partner-lander-exceeded {
      margin: 20px;
      text-align: center;
      h2 {
        font-size: 2.2rem;
      }
      .screenshots {
        max-width: 480px;
        margin: 40px auto;
      }
      img {
        width: 100%;
      }
    }
  }

  .partner-lander-overview {
    flex-grow: 1;
    flex-shrink: 1;
    margin-left: 40px;
    margin-right: 20px;
    @media screen and (max-width: 1290px) {
      margin-left: 20px;
    }
    @media screen and (max-width: $mobile-break) {
      margin: 0;
      flex-grow: 0;
      flex-shrink: 0;
    }

    .intro {
      color: #fff;
      min-height: 220px;
      @media screen and (max-width: $mobile-break) {
        background: url('../../assets/images/login-bg.jpg') no-repeat center center;
        margin: 0;
        padding: 10px;
        min-height: auto;
      }
      .header-logos {
        margin-top: 10px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 1000px) {
          margin-bottom: 0;
        }
        @media screen and (max-width: $mobile-break) {
          margin: 0;
        }

        .carbly-logo {
          width: 200px;
          height: 63px;
          @media screen and (max-width: 1290px) {
            width: 120px;
            height: 38px;
          }
        }
        .edge-logo {
          width: 220px;
          height: 66px;
          @media screen and (max-width: 1290px) {
            width: 120px;
            height: 36px;
          }
        }
        .xcira-logo {
          width: 200px;
          height: 81px;
          @media screen and (max-width: 1290px) {
            width: 110px;
            height: 44px;
          }
        }
        svg {
          margin: 0 10px;
        }
      }
    }

    .partner-body {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      #mobile-show-more {
        display: none;
      }
      @media screen and (max-height: 900px) {
        position: inherit;
      }
      @media screen and (max-width: $mobile-break) {
        position: inherit;
        padding: 10px;
        #mobile-show-more {
          display: block;
          text-align: center;
        }
        #mobile-more {
          display: none;
        }
        .mobile-showing-more {
          display: block !important;
        }
      }

      .screenshots {
        max-width: 480px;
        margin: 40px auto;
        @media screen and (max-width: $mobile-break) {
          display: none;
        }
        img {
          width: 100%;
        }
      }
      .video-thumbnail {
        text-align: center;
        margin: 40px auto;
        max-width: 400px;
        @media screen and (max-width: $mobile-break) {
          margin: 20px auto;
          max-width: 150px;
        }
        a {
          text-decoration: none;
        }
        img {
          width: 100%;
        }
      }
    }


    .provider-logos {
      margin: 40px 0;
      text-align: center;
      img {
        height: 40px;
        margin: 10px;
      }
      img.real-retail, img.jd-power {
        height: 20px;
      }
      img.carfax, img.live-local-market {
        height: 30px;
      }
    }

    .login {
      text-align: center;
    }
    
  }
  .partner-spinner {
    margin-top: 150px;
    margin-bottom: 30px;
  }
}

.manual-zip-entry-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  input {
    height: 40px;
    margin-right: 10px;
  }
  button {
    min-width: 200px;
  }
}

.free-trial {
  text-align: center;
  a {
    display: inline-block;
    margin: 10px auto;
    background-color: $blue-accent-color;
    color: #fff;
    font-weight: bold;
    font-size: 1.2rem;;
    padding: 10px 20px;
    border-radius: 8px;
  }
}

.partner-footer-c {
  background-color: #111;
  color: #fff;
  background: url('../../assets/images/login-bg.jpg') no-repeat center bottom;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 40px 20px;
  .partner-footer {
    max-width: 500px;
    margin: 0 auto;
    .partner-footer-body {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 20px;
      @media screen and (max-width: $mobile-break) {
        display: block;
        text-align: center;
      }
      h3 {
        display: block;
        color: #fff;
        font-weight: bold;
        font-size: 1.2rem;
        margin-bottom: 5px;
      }
      div {
        margin-bottom: 5px;
      }
      img {
        width: 200px;
        height: 63px;
        margin-right: 100px;
        @media screen and (max-width: $mobile-break) {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
      svg {
        margin-right: 10px;
      }
      a {
        color: #fff;
      }
    }
  }
  .partner-legal {
    text-align: center;
    margin-top: 40px;
    font-size: 0.9rem;
  }
}
